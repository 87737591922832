import { AfterViewChecked, Component, Input, Renderer2, ViewChild } from '@angular/core';
import { InputType } from '../../../../enums/input-type.enum';
import { TableColumnCarousel } from '../../models/table-column-carousel.model';

@Component({
  selector: 'sdx-table-column-carousel-footer',
  templateUrl: './table-column-carousel-footer.component.html',
  styleUrls: ['./table-column-carousel-footer.component.scss'],
})
export class TableColumnCarouselFooterComponent implements AfterViewChecked {
  inputType = InputType.ROUNDED;

  @Input()
  column!: TableColumnCarousel<any>;

  @Input()
  columnsWidth!: number;

  @ViewChild('carouselFooter', { static: false })
  carouselFooter: any;

  @Input()
  enabledCarousel!: boolean;

  @Input()
  total: any;

  constructor(private renderer: Renderer2) {}
  ngAfterViewChecked() {
    this.calculateWidth();
  }

  private calculateWidth() {
    if (this.column && this.columnsWidth && this.carouselFooter) {
      const baseWidth = 1200;
      const itemSize = 120;

      const widthRemaining = baseWidth - this.columnsWidth;
      const carouselWidth = this.column.items.length * itemSize;
      if (carouselWidth > widthRemaining) {
        const finalCarouselWidth = Math.floor(widthRemaining / itemSize) * itemSize;
        this.renderer.setStyle(this.carouselFooter.nativeElement, 'max-width', `${finalCarouselWidth}px`);
      } else {
        this.renderer.setStyle(this.carouselFooter.nativeElement, 'max-width', `${carouselWidth}px`);
      }
    }
  }

  scrollTo(newPosition: any) {
    this.carouselFooter.nativeElement.scrollTo(newPosition, 0);
  }

  justifyContent() {
    return this.enabledCarousel ? { 'justify-content': 'flex-start' } : { 'justify-content': 'center' };
  }
  trackByFn(index: any, item: any) {
    return item.total;
  }
}
