<footer class="footer_container" *ngIf="showFooter && routerIsActive" [ngClass]="{ fixed: fixed }">
  <nav class="footer_nav">
    <ul>
      <li class="footer_logo">
        <a [routerLink]="['/home']">
          <img src="assets/images/sodexo-logo-simple.svg" />
        </a>
      </li>
      <li class="footer_pipe"></li>
      <li class="footer_terms">
        <a
          href="https://www.sodexobeneficios.com.br/informacoes-legais/termos-de-uso.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="footer_text">Termos de uso</span>
        </a>
        <span class="footer_pipe"></span>
        <a
          href="https://www.sodexobeneficios.com.br/informacoes-legais/politica-de-privacidade.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="footer_text">Política de Privacidade</span>
        </a>
        <span class="footer_pipe"></span>
        <a
          href="https://www.sodexobeneficios.com.br/informacoes-legais/seguranca-digital.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="footer_text">Segurança Digital</span>
        </a>
        <span class="footer_pipe"></span>
        <a [routerLink]="['faq']">
          <span class="footer_text">Perguntas Frequentes</span>
        </a>
      </li>
      <li class="footer_copyright"><span>©2021 Sodexo. Todos os direitos reservados</span></li>
    </ul>
  </nav>
</footer>
