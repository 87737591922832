import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'sdx-callback-connect',
  templateUrl: './callback-connect.component.html',
})
export class CallbackConnectComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.authService.fetchAccessTokenConnect().subscribe(() => {
      this.spinner.hide();
      this.router.navigate(['/']);
    });
  }
}
