import moment from 'moment';

import { Mapper } from './mapper';
import { Beneficiary } from '../models/beneficiary.model';
import { BeneficiaryDto } from '../dtos/beneficiary.dto';
import { BeneficiaryFormDto } from '../dtos/beneficiary-form.dto';

export class BeneficiaryMap extends Mapper<Beneficiary, BeneficiaryDto> {
  convertToDto(model: Beneficiary, id: number): BeneficiaryDto {
    const dto: BeneficiaryDto = {
      cpf: '',
      name: '',
      registry: 0,
      email: '',
      phone: 0,
      birthDayOfTheMonth: 0,
      birthMonth: 0,
      birthYear: 0,
      type: '',
      companyOfficialDocument: '',
      enabled: false,
      isNew: false,
      clientId: 0,
    };
    dto.cpf = model.cpf;
    dto.name = model.name;
    dto.registry = model.registry;
    dto.email = model.email;
    dto.phone = model.phone;
    dto.birthDayOfTheMonth = model.birthDayOfTheMonth;
    dto.birthMonth = model.birthMonth;
    dto.birthYear = model.birthYear;
    dto.type = 'TITULAR';
    dto.isNew = model.isNew;
    dto.clientId = id;
    return dto;
  }

  convertToCreateDto(model: Beneficiary) {
    const dto: BeneficiaryFormDto = {
      companyOfficialId: '',
      clientId: '',
      id: 0,
      officialDocument: '',
      name: '',
      email: '',
      phone: 0,
      birthDate: '',
      deliveryPlace: {},
      genre: '',
      maritalStatus: '',
      workplace: '',
      workplaceUnity: '',
    };
    dto.officialDocument = model.cpf;
    dto.name = model.name;
    dto.email = model.email;
    dto.phone = model.phone;

    dto.birthDate = moment(model.birthDate).format('YYYY-MM-DD');
    dto.deliveryPlace = {
      id: model.deliveryPlaceId,
    };

    dto.genre = model.genre;
    dto.maritalStatus = model.maritalStatus;
    dto.workplace = model.workplace;
    dto.workplaceUnity = model.workplaceUnity;

    dto.companyOfficialId = model.companyOfficialDocument;
    dto.clientId = model.clientId;
    dto.id = model.beneficiaryId;
    return dto;
  }

  convertToModel(dto: BeneficiaryDto): Beneficiary {
    throw new Error('Method not implemented.');
  }
}
