<sdx-header></sdx-header>
<div class="body__wrapper body__overflow">
  <router-outlet></router-outlet>
</div>
<sdx-footer></sdx-footer>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="large"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="true"
></ngx-spinner>
