import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CnpjPipe, CpfPipe } from 'ng2-brpipes';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { FormsModule as SdxFormsMudule } from 'src/app/shared/modules/forms/forms.module';
import { PageComponent } from './components/page/page.component';
import { ModalModule } from './modules/modal/modal.module';
import { TableModule } from './modules/table/table.module';
import { SdxTimelineModule } from './modules/timeline/timeline.module';
import { MaskTicketPipe } from './pipes/mask-ticket.pipe';

@NgModule({
  declarations: [PageComponent, MaskTicketPipe],
  imports: [
    CommonModule,
    InputTextModule,
    TableModule,
    PanelModule,
    SdxFormsMudule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    SdxTimelineModule,
  ],
  exports: [PageComponent, TableModule, ModalModule, PanelModule, MaskTicketPipe, SdxTimelineModule],
  providers: [CpfPipe, CnpjPipe],
})
export class SharedModule {}
