import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { TimelineModule } from 'primeng/timeline';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [TimelineComponent],
  imports: [CommonModule, TimelineModule, SkeletonModule],
  exports: [TimelineComponent],
})
export class SdxTimelineModule {}
