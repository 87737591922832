import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackConnectComponent } from '../callback-connect/callback-connect.component';
import { AuthGuard } from '../core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth/cb',
    component: CallbackConnectComponent,
  },
  {
    path: 'home',
    loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'order',
    loadChildren: () => import('./4C/order/order.module').then((m) => m.OrderModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'beneficiary',
    loadChildren: () => import('./beneficiary/beneficiary.module').then((m) => m.BeneficiaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./4C/order-history/order-history.module').then((m) => m.OrderHistoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'order-error/:id',
    loadChildren: () => import('../features/4C/order-error/order-error.module').then((m) => m.OrderErrorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bulk-order',
    loadChildren: () => import('./4C/bulk-order/bulk-order.module').then((m) => m.BulkOrderModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'income-report',
    loadChildren: () => import('./4C/income-report/income-report.module').then((m) => m.IncomeReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'wallet-maneger',
    loadChildren: () => import('./wallet-maneger/wallet-maneger.module').then((m) => m.WalletManegerModule),
  },
  {
    path: 'delivery-report',
    loadChildren: () => import('./4C/delivery-report/delivery-report.module').then((m) => m.DeliveryReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('../error-page/error-page.module').then((m) => m.ErrorPageModule),
  },
  {
    path: 'batch-order',
    loadChildren: () => import('./batch-order/batch-order.module').then((m) => m.BatchOrderModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
