import { Component, Input } from '@angular/core';
@Component({
  selector: 'sdx-table-column-sorter',
  templateUrl: './table-column-sorter.component.html',
  styleUrls: ['./table-column-sorter.component.scss']
})
export class TableColumnSorterComponent {
  @Input()
  sortByField!: string;

  constructor() {}
}
