import { ColumnType } from '../enums/column-type.enum';
import { ColumnEvents } from './column-events.model';
import { ColumnAction } from './column-action.model';
import { TableColumnFilterModel } from './table-column-filter.model';

export class TableColumn<Type> {
  id!: string;
  headerTitle?: string;
  model?: string;
  hasFilter?: boolean;
  filter?: TableColumnFilterModel;
  hasOrder?: boolean;
  type?: ColumnType;
  width?: number;
  events?: ColumnEvents;
  actions?: Array<ColumnAction>;
  defaultValue?: any;
  format?: any;
  unmask?: boolean;
  iconName?: string;
  hasSelectRecord?: boolean;
}
