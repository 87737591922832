import { CommonModule } from '@angular/common';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule as TableModulePrime } from 'primeng/table';
import { TableColumnSorterComponent } from './components/table-column-sorter/table-column-sorter.component';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { TableComponent } from './components/table/table.component';
import { ColumnStatePipe } from './pipes/column-state.pipe';
// tslint:disable-next-line:max-line-length
import { TableColumnCarouselHeaderComponent } from './components/table-column-carousel-header/table-column-carousel-header.component';
// tslint:disable-next-line:max-line-length
import { TableColumnCarouselContentComponent } from './components/table-column-carousel-content/table-column-carousel-content.component';
// tslint:disable-next-line:max-line-length
import { TableColumnCarouselActionsComponent } from './components/table-column-carousel-actions/table-column-carousel-actions.component';
import { TableColumnCarouselFooterComponent } from './components/table-column-carousel-footer/table-column-carousel-footer.component';

import {
  PlxButtonModule,
  PlxCheckboxModule,
  PlxIconModule,
  PlxInputModule,
  PlxInputsValueAcessor,
  PlxSelectModule,
} from '@pluxee-design-system/angular';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule as TooltipModulePrime } from 'primeng/tooltip';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';

@NgModule({
  declarations: [
    TableColumnSorterComponent,
    TableFilterComponent,
    TableComponent,
    ColumnStatePipe,
    TableColumnCarouselHeaderComponent,
    TableColumnCarouselContentComponent,
    TableColumnCarouselFooterComponent,
    TableColumnCarouselActionsComponent,
  ],
  imports: [
    CommonModule,
    TableModulePrime,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    RadioButtonModule,
    TooltipModulePrime,
    PlxInputModule,
    PlxInputsValueAcessor,
    PlxButtonModule,
    PlxSelectModule,
    PlxIconModule,
    PlxCheckboxModule,
  ],
  exports: [TableComponent, TableFilterComponent, TableColumnSorterComponent, ColumnStatePipe],
  schemas: [NO_ERRORS_SCHEMA],
})
export class TableModule {}
