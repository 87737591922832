import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthGuard } from '../../guards/auth-guard.service';

@Component({
  selector: 'sdx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  readonly showFooterPagesUrlRegex = new RegExp(
    /(^\/produto\/)|(^\/orders$)|(^\/checkout$)|enderecos|new-order|beneficiary|(^\/home)|home|order|(^\/)/
  );
  readonly faqUrlRegex = new RegExp(/(^\/faq)/);
  fixed = false;
  showFooter = true;
  routerSubscription$: Subscription = new Subscription;
  private routerIsActive$: any;
  routerIsActive = false;

  constructor(private router: Router, private authGuardService: AuthGuard) {}

  ngOnInit(): void {
    this.subscribeRouterActivated();
    this.subscribeRouterEvent();
  }

  ngOnDestroy(): void {
    this.unsubscribeRouterActivated();
    this.unsubscribeRouterEvent();
  }

  subscribeRouterEvent(): void {
    this.routerSubscription$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.showFooter = !this.showFooterPagesUrlRegex.test(event.url);
        this.fixed = this.faqUrlRegex.test(event.url);
      });
  }

  unsubscribeRouterEvent(): void {
    this.routerSubscription$.unsubscribe();
  }

  private subscribeRouterActivated() {
    this.routerIsActive$ = this.authGuardService
      .routerIsActive()
      .subscribe(routerIsActive => (this.routerIsActive = routerIsActive));
  }

  private unsubscribeRouterActivated() {
    if (this.routerIsActive$) {
      this.routerIsActive$.unsubscribe();
    }
  }
}
