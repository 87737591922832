import { Component, HostListener, ViewChild, ElementRef, AfterViewChecked, Renderer2 } from '@angular/core';

@Component({
  selector: 'sdx-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements AfterViewChecked {
  @ViewChild('header', { static: false })
  header?: ElementRef;

  @ViewChild('body', { static: false })
  body?: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.applyBodyResize();
  }

  @HostListener('window:resize', ['$event'])
  applyBodyResize(event?: any) {
    if (this.header && this.body) {
      const mainHeaderHeight = 80;
      const mainFooterHeight = 58;
      const pageHeaderHeight = this.header.nativeElement.clientHeight;
      const pagefooterPosition = pageHeaderHeight + mainFooterHeight + mainHeaderHeight;
      this.renderer.setStyle(this.body.nativeElement, 'min-height', `calc(100vh - ${pagefooterPosition}px`);
    }
  }
}
