import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private afterRouterActive: EventEmitter<boolean> = new EventEmitter();

  constructor(private authService: AuthService, private router: Router, private connectService: OidcSecurityService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (state.root.queryParams && state.root.queryParams['jsessionid']) {
      return this.handleLoginEntrypoint(state);
    }
    const isLogged = this.authService.isAuthenticated();
    if (!isLogged) {
      this.authService.logout();
    }

    if (localStorage.getItem('isConnect')) {
      const result = this.connectService.isAuthenticated$.pipe(
        take(1),
        map(({ isAuthenticated }) => {
          this.afterRouterActive.next(isAuthenticated);
          if (!isAuthenticated) {
            this.connectService.authorize();
            return false;
          }
          return isAuthenticated;
        })
      );
      return result;
    }
    this.afterRouterActive.next(isLogged);
    return isLogged;
  }

  private handleLoginEntrypoint(state: RouterStateSnapshot) {
    const isProdOrHml = environment.environmentName === 'homolog' || environment.environmentName === 'prod';
    const token = !isProdOrHml ? state.root.queryParams['token'] : null;
    const isConnect = state.root.queryParams['isconnect'] === 'true';
    const clientId = state.root.queryParams['externalid'] || 'pedefacil';
    return this.authService.authenticate(token, state.root.queryParams['jsessionid'], clientId, isConnect).pipe(
      map((logged) => {
        if (logged) {
          this.router.navigate([''], {
            queryParams: {
              token: null,
            },
            queryParamsHandling: 'merge',
          });
        }
        return false;
      }),
      catchError((err) => {
        return this.router.navigate(['error/']);
      })
    );
  }

  routerIsActive() {
    return this.afterRouterActive;
  }
}
