export class TableData {
  //private _recordsPerPage: Map<number, Array<any>> = new Map();
  private _recordsPerPage: Map<string, Array<any>> = new Map();
  private _records: Array<any> = [];
  totalRecords: number = 0;
  private _emptyMessage: Array<string> = [];
  private _columnsTotals: Array<any> = [];
  selectedRecords: string = '';
  currentTotalRecords = 0;

  setRecordsPerPage(cacheKey: any, records: Array<any>) {
    this._recordsPerPage.set(cacheKey, records);
    this._records = records;
  }

  setRecords(records: Array<any>) {
    this._records = records;
  }

  setEmptyMessage(emptyMessage: Array<string>) {
    this._emptyMessage = emptyMessage;
  }

  hasRecords(page: any): boolean {
    return this._recordsPerPage.has(page);
  }

  loadCachedRecords(page: number) {
    this._records = this.getRecords(page);
  }

  getRecords(page: any): Array<any> {
    return this._recordsPerPage.get(page) as Array<any>;
  }

  getAllRecords(): Array<any> {
    return Array.from(this._recordsPerPage.values()).reduce((previous, next) => previous.concat(next));
  }

  get records(): Array<any> {
    return this._records;
  }

  clearData() {
    this._recordsPerPage = new Map();
    this._records = new Array<any>();
  }

  get emptyMessage(): Array<string> {
    return this._emptyMessage;
  }

  setColumnsTotals(columnsTotals: Array<any>) {
    this._columnsTotals = columnsTotals;
  }

  get columnsTotals(): Array<any> {
    return this._columnsTotals;
  }

  setTotalRecords(totalRcrds: any) {
    this.totalRecords = totalRcrds;
  }

  get totalRcrds(): number {
    return this.totalRecords;
  }

  setSelectedRecords(value: string) {
    this.selectedRecords = value;
  }
}
