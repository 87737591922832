import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressesByZipCode } from 'src/app/shared/dtos/addresses-by-zipcode.dto';

import { AddressByZipCode } from 'src/app/shared/dtos/address-by-zipcode.dto';
import { BeneficiaryInputDto } from 'src/app/shared/dtos/beneficiary-input.dto';
import { BeneficiarySaveOutput } from 'src/app/shared/dtos/beneficiary-save-output.dto';
import { BeneficiaryStatus } from 'src/app/shared/enums/beneficiary-status.enum';
import { Beneficiaries } from 'src/app/shared/models/beneficiaries-payload.model';
import { TableData } from 'src/app/shared/modules/table/models/table-data.model';
import { BeneficiaryFormDto } from '../../shared/dtos/beneficiary-form.dto';
import { BeneficiaryMap } from '../../shared/mappers/beneficiary.map';
import { Beneficiary } from './../../shared/models/beneficiary.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService {
  private readonly path: string = '/beneficiaries';
  private readonly pathOrder = '/suite/orders';
  private readonly path4c: string = '/suite/beneficiaries';
  private readonly beneficiaryMap = new BeneficiaryMap();

  constructor(private http: HttpService<any>) {
    moment.locale('pt-br');
  }

  getAllBeneficiaries(beneficiaryInputDto: BeneficiaryInputDto): Observable<TableData> {
    const params = this.buildApiParams(beneficiaryInputDto, 'all');
    return this.http.get(this.path4c, params).pipe(
      map((data: any) => {
        return this.mapApiResponseToTableData(data, params);
      })
    );
  }

  getAllActiveBeneficiaries(beneficiaryInputDto: BeneficiaryInputDto): Observable<TableData> {
    const params = this.buildApiParams(beneficiaryInputDto, '');
    return this.http.get(this.path4c, params).pipe(
      map((data: any) => {
        return this.mapApiResponseToTableData(data, params);
      })
    );
  }

  searchAddressByPostalCode(zipCode: string): Observable<AddressesByZipCode> {
    return this.http.get(`/suite/address?zipCode=${zipCode}`);
  }

  saveBeneficiary(beneficiary: Beneficiary): Observable<BeneficiarySaveOutput> {
    const dto = this.beneficiaryMap.convertToCreateDto(beneficiary);
    if (beneficiary.isNew) {
      return this.insertBeneficiary(dto);
    } else {
      return this.updateBeneficiary(dto);
    }
  }

  async exists(cpf: string, cnpj: string): Promise<any> {
    const path = `${this.path}/exists?cpf=${cpf}&cnpj=${cnpj}`;
    return this.http
      .get(path)
      .pipe(map((response: any) => (response && response.status ? response.status : false)))
      .toPromise();
  }

  private insertBeneficiary(beneficiary: BeneficiaryFormDto) {
    return this.http.post(this.path4c, beneficiary) as Observable<BeneficiarySaveOutput>;
  }

  private updateBeneficiary(beneficiary: BeneficiaryFormDto) {
    return this.http.put(
      `${this.path4c}/${beneficiary.officialDocument}`,
      beneficiary
    ) as Observable<BeneficiarySaveOutput>;
  }

  updateBeneficiaryStatus(beneficiaries: Beneficiaries, clientId: string) {
    const path = `${this.path4c}/status?clientId=${clientId}`;
    return this.http.put(path, beneficiaries);
  }

  downloadReportBeneficiary(clientId: string, officialDocument: string): Observable<any> {
    return this.http.get(`${this.pathOrder}/${clientId}/${officialDocument}/report`, undefined, 'arraybuffer');
  }

  private onGetBeneficiariesByClientIdSuccess(params: any, data: BeneficiaryQueryDto): any {
    if (data) {
      data.beneficiaries?.forEach((beneficiary: Beneficiary) => {
        this.transformBeneficiary(beneficiary);
      });
      return this.createTableData(data, params);
    }
  }

  private mapApiResponseToTableData(data: any, params: any): TableData {
    const response: BeneficiaryQueryDto = new BeneficiaryQueryDto();
    response.beneficiaries = data.items;
    response.totalRecords = data.totalRecords;
    return this.onGetBeneficiariesByClientIdSuccess(params, response);
  }

  private transformBeneficiary(beneficiary: Beneficiary) {
    try {
      beneficiary.registry = Math.round(Math.random() * 1000000);
      beneficiary.name = beneficiary.name;
      beneficiary.status = BeneficiaryStatus.ACTIVE;
      beneficiary.value = 'R$ 0,00';
      beneficiary.cpf = beneficiary.cpf.padStart(11, '0');
      beneficiary.birthDate = beneficiary.birthDate ? moment(beneficiary.birthDate).toDate() : undefined;
      beneficiary.birthDateStr = beneficiary.birthDate ? moment(beneficiary.birthDate).format('DD/MM/YYYY') : '';
      beneficiary.dependents = [];
      beneficiary.benefits = beneficiary.benefits;
      beneficiary.deliveryAddress = beneficiary.delivery ? this.mountAddress(beneficiary.delivery) : '';

      if (beneficiary.phone && beneficiary.phone.toString().length < 10) {
        let phoneNumber = `${beneficiary.ddd}${beneficiary.phone}`;
        beneficiary.phone = Number(phoneNumber);
      }
    } catch (err) {
      console.log('beneficiario com erro %s | error: %o', beneficiary.name, err);
    }
    return beneficiary;
  }

  public mountAddress(delivery: AddressByZipCode): string {
    const street = [delivery.streetTypeAbbreviation, delivery.streetName].filter(Boolean).join(' ');
    const streetNumberComplement = [street, delivery.number, delivery.complement].filter(Boolean).join(', ');
    const address = [streetNumberComplement, delivery.neighborhood, delivery.cityName, delivery.federalUnit]
      .filter(Boolean)
      .join(' - ');
    return address;
  }

  private createTableData(data: BeneficiaryQueryDto, params: any): TableData {
    const tableData = new TableData();
    //@ts-ignore
    tableData.totalRecords = data.totalRecords;
    tableData.currentTotalRecords = data.totalRecords!;
    tableData.setTotalRecords(data.totalRecords);
    tableData.setRecordsPerPage(params.pageNumber, data.beneficiaries);
    tableData.setSelectedRecords(`${data.totalRecords} de ${data.totalRecords} selecionados`);
    return tableData;
  }

  private buildApiParams(beneficiaryInputDto: BeneficiaryInputDto, status: string): any {
    return {
      pageNumber: beneficiaryInputDto.pageNumber,
      pageSize: beneficiaryInputDto.pageSize,
      clientId: beneficiaryInputDto.clientId,
      name: beneficiaryInputDto.filter?.name || '',
      cpf: beneficiaryInputDto.filter?.cpf || '',
      email: beneficiaryInputDto.filter?.email || '',
      wholeSale: false,
      status: status,
    };
  }
}

class BeneficiaryQueryDto {
  totalRecords?: number;
  beneficiaries!: Array<Beneficiary>;
}
