import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageToastService {
  constructor(private messageService: MessageService) {}

  showSuccessMessage(messageDetail: string, summary: string) {
    this.messageService.add({
      severity: 'success',
      summary: messageDetail,
      detail: summary,
      life: 5000
    });
  }
  showErrorMessage(messageDetail: string, summary: string) {
    this.messageService.add({
      severity: 'error',
      summary: messageDetail,
      detail: summary,
      life: 5000
    });
  }
}
