<div class="container__tabs--steps">
  <div class="col-8" style="flex: 1" *ngIf="!timeline">
    <p-skeleton width="100%" height=".5rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="100%" height=".5rem" styleClass="mb-2"></p-skeleton>
  </div>
  <p-timeline [value]="timeline" layout="horizontal" id="container-timeline">
    <ng-template pTemplate="opposite" let-timeline>
      <div
        [ngClass]="{
          failed: timeline.status === 'FAILED' && timeline.status !== 'DONE',
          active: timeline.status === 'DONE' || timeline.status !== 'FAILED',
          disable: timeline.status !== 'DONE' && timeline.status !== 'FAILED',
          canceled: timeline.status === 'CANCELED'
        }"
        id="label-{{ timeline.label }}"
        class="fw-600"
      >
        {{ timeline.status === 'FAILED' ? '' : timeline.label }}
      </div>
      <p-skeleton *ngIf="!timeline" width="75%"></p-skeleton>
    </ng-template>
    <ng-template pTemplate="marker" let-timeline>
      <div
        [ngClass]="{
          circle_timeline: timeline.status === 'DONE' || timeline.status === 'FAILED' || timeline.status === 'CANCELED',
          circle_timeline_disable:
            timeline.status !== 'DONE' && timeline.status !== 'FAILED' && timeline.status !== 'CANCELED',
          circle_timeline_falied: timeline.status === 'FAILED'
        }"
        id="circle"
      ></div>
    </ng-template>
    <ng-template pTemplate="content" let-timeline>
      <div
        [ngClass]="{
          failed: timeline.status === 'FAILED' && timeline.status !== 'DONE',
          active: timeline.status === 'DONE' || timeline.status !== 'FAILED',
          disable: timeline.status !== 'DONE' && timeline.status !== 'FAILED',
          canceled: timeline.status === 'CANCELED'
        }"
        id="value-{{ timeline.value }}"
        class="fw-400"
      >
        {{ timeline.value }}
      </div>
    </ng-template>
  </p-timeline>
</div>
