import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsAndConditionsRequest } from '../../shared/models/terms-and-conditions-request.model';
import { TermsAndConditions } from '../../shared/models/terms-and-conditions.model';
import { User } from '../../shared/models/user.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly path: string = '/users';
  private readonly pathToken: string = '/current';

  constructor(private http: HttpService<any>) {}

  getCurrentUser(newClientId?: string): Observable<User> {
    const savedClientId = localStorage.getItem('clientId');
    const clientId = newClientId ?? savedClientId;
    return this.http.get(`/suite/users/profile/${clientId}`);
  }

  getCurrentUserWithCustomHeader(token: string): Observable<User> {
    return this.http.getWithCustomHeaders(this.path + this.pathToken, {}, { Authorization: token });
  }

  getUserFirstAccess(clientId: string): Observable<TermsAndConditions> {
    return this.http.get(`/suite/customers/${clientId}`);
  }

  acceptTermsAndConditions(clientId: string, request: TermsAndConditionsRequest): Observable<any> {
    return this.http.post(`/suite/customers/${clientId}/accept-terms-and-conditions`, request);
  }
}
