import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, filter } from 'rxjs';

import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { Protocol } from '../../shared/models/protocol.model';

const API_BASE_URL = environment.baseApiUrl;
@Injectable({
  providedIn: 'root',
})
export class HttpService<D> {
  private static httpHeaders: any;

  constructor(
    protected http: HttpClient,
    protected cookieService: CookieService,
    private connectService: OidcSecurityService,
    private connectEvents: PublicEventsService
  ) {
    if (localStorage.getItem('isConnect')) {
      this.connectEvents
        .registerForEvents()
        .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
        .subscribe(() => {
          this.connectService.getAccessToken().subscribe((accessToken) => {
            if (!accessToken) return;
            HttpService.httpHeaders = new HttpHeaders({
              Authorization: `Bearer ${accessToken}`,
              'X-login-type': 'connect',
            });
          });
        });
    }
  }

  get(path: string, data = {}, responseType?: any): Observable<D> {
    return this.http.get<D>(API_BASE_URL + path, { params: data, headers: this.getHeaderParams(), responseType });
  }

  getWithCustomHeaders(path: string, data = {}, headers: any, responseType?: any): Observable<D> {
    return this.http.get<D>(API_BASE_URL + path, { params: data, headers, responseType });
  }

  putWithCustomHeaders(path: string, data = {}, headers: any, responseType?: any, apiBaseUrl?: boolean): Observable<D> {
    return this.http.put<D>(apiBaseUrl ? API_BASE_URL + path : path, { params: data, headers, responseType });
  }

  getAll(path: string, data = {}): Observable<Array<D>> {
    return this.http.get<Array<D>>(API_BASE_URL + path, { params: data, headers: this.getHeaderParams() });
  }

  post(path: string, data = {}, protocol?: Protocol, queryParams = {}): Observable<D> {
    data = Object.assign(data, { protocol });
    return this.http.post<D>(API_BASE_URL + path, data, {
      headers: this.getHeaderParams(),
      params: queryParams,
    });
  }

  put(path: string, data = {}, protocol?: Protocol): Observable<D> {
    data = Object.assign(data, { protocol });
    return this.http.put<D>(API_BASE_URL + path, data, { headers: this.getHeaderParams() });
  }

  delete(path: string, protocol?: Protocol, params = {}): Observable<any> {
    const options = {
      headers: this.getHeaderParams(),
      body: protocol,
      params,
    };
    return this.http.delete<any>(API_BASE_URL + path, options);
  }

  setConnectAccessToken(accessToken: string) {
    HttpService.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
      'X-login-type': 'connect',
    });
  }

  getHeaderParams() {
    if (localStorage.getItem('isConnect')) return HttpService.httpHeaders;
    const isLocalEnvironment = environment.environmentName === 'local';
    if (isLocalEnvironment) {
      const authorizationToken = this.cookieService.get('Authorization');
      HttpService.httpHeaders = new HttpHeaders({
        Authorization: authorizationToken,
        'X-login-type': 'pedefacil',
      });
      return HttpService.httpHeaders;
    }
    HttpService.httpHeaders = new HttpHeaders({
      'X-login-type': 'pedefacil',
      Authorization: 'pedefacil',
    });
    return HttpService.httpHeaders;
  }
}
