import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth.service';
import { MessageToastService } from './core/services/message.toast.service';
import { HttpStatus } from './shared/enums/http-status';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private toastService: MessageToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLocalEnvironment = environment.environmentName === 'local';
    const isConnect = !!request.url.match(environment.connectUrl);
    if (!isLocalEnvironment && !isConnect) {
      request = request.clone({ withCredentials: true });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === HttpStatus.UNAUTHORIZED) {
          this.toastService.showErrorMessage('Sessão expirada!', 'De um reload na pagina e faça login novamente.');
          this.authService.onAfterLogout();
        } else if (err.status === HttpStatus.INTERNAL_SERVER_ERROR) {
          this.toastService.showErrorMessage(
            'Erro Interno no Servidor',
            'Ocorreu um erro. Por favor, tente novamente.'
          );
        } else if (err.status === HttpStatus.BAD_REQUEST) {
          this.toastService.showErrorMessage('Erro', this.errorMessage(err, 'Erro na chamada do serviço'));
        } else if (err.status === HttpStatus.NOT_FOUND) {
          this.toastService.showErrorMessage('Erro', this.errorMessage(err, 'URI do serviço não encontrada'));
        } else if (err.status === HttpStatus.GATEWAY_TIMEOUT) {
          this.toastService.showErrorMessage('Erro', this.errorMessage(err, 'Timeout no servidor. Tente novamente!'));
        } else {
          if (err.status !== HttpStatus.OK) {
            this.toastService.showErrorMessage('Erro', 'Ocorreu um erro. Por favor, tente novamente.');
            return throwError(() => {
              return new Error(err);
            });
          }
        }

        return throwError(() => {
          return new Error(err);
        });
      })
    );
  }

  private errorMessage(err: any, message: string): string {
    const uintArray = new Uint8Array(err.error);
    if (uintArray.length > 0) {
      const decoder = new TextDecoder('utf-8');
      const responseMessage = JSON.parse(decoder.decode(uintArray));
      return responseMessage.message;
    } else {
      return err.error && err.error.message ? err.error.message : message;
    }
  }
}
