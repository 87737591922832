import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalSize } from './modal-size.enum';

@Component({
  selector: 'sdx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  modalSize = 40;

  @Input()
  show = false;

  @Input()
  size?: string;

  @Input()
  positionTop?: number;

  @Output()
  onHide: EventEmitter<any> = new EventEmitter();

  @Output()
  onShow: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.resizeModal();
  }

  resizeModal() {
    switch (this.size) {
      case ModalSize.SMALL:
        this.modalSize = 30;
        break;
      case ModalSize.MEDIUM:
        this.modalSize = 40;
        break;
      case ModalSize.BIG:
        this.modalSize = 60;
        break;
      case ModalSize.LARGE:
        this.modalSize = 70;
        break;
      default:
        this.modalSize = 40;
        break;
    }
  }

  hideEvent() {
    this.onHide.emit();
  }

  showEvent() {
    this.onShow.emit();
  }
}
