import { TableColumn } from './table-column.model';
import { ColumnState } from './column-state.model';
import { ColumnType } from '../enums/column-type.enum';

export class TableColumnState<T> extends TableColumn<T> {
  states?: Array<ColumnState>;
  headerState: any;

  constructor() {
    super();
    this.type = ColumnType.STATE;
  }
}
