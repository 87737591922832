import { AfterViewChecked, Component, Input, ViewChild, Renderer2 } from '@angular/core';
import { TableColumnCarousel } from '../../models/table-column-carousel.model';

@Component({
  selector: 'sdx-table-column-carousel-actions',
  templateUrl: './table-column-carousel-actions.component.html',
  styleUrls: ['./table-column-carousel-actions.component.scss']
})
export class TableColumnCarouselActionsComponent implements AfterViewChecked {
  @Input()
  column!: TableColumnCarousel<any>;

  @Input()
  columnsWidth!: number;

  @ViewChild('carouselActions', { static: false })
  private carouselActions: any;

  constructor(private renderer: Renderer2) {}

  ngAfterViewChecked() {
    this.calculateWidth();
  }

  scrollTo(direction: any) {
    const itemSize = 120;
    const scrollPostion = this.carouselActions.nativeElement.scrollLeft;
    let newPosition;
    if (direction === 'left') {
      newPosition = scrollPostion - itemSize;
    } else if (direction === 'right') {
      newPosition = scrollPostion + itemSize;
    }
    this.carouselActions.nativeElement.scrollTo(newPosition, 0);
  }

  private calculateWidth() {
    if (this.column && this.columnsWidth && this.carouselActions) {
      const baseWidth = 1200;
      const itemSize = 120;

      const widthRemaining = baseWidth - this.columnsWidth;
      const carouselWidth = this.column.items.length * itemSize;
      if (carouselWidth > widthRemaining) {
        const finalCarouselWidth = Math.floor(widthRemaining / itemSize) * itemSize;
        this.renderer.setStyle(this.carouselActions.nativeElement, 'max-width', `${finalCarouselWidth}px`);
      } else {
        this.renderer.setStyle(this.carouselActions.nativeElement, 'max-width', `${carouselWidth}px`);
      }
    }
  }
}
