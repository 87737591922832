import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/models/user.model';
import { HttpService } from './http.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly logoutPageUrl = environment.logoutPedeFacilPageUrl;
  private userStateChanged: EventEmitter<User> = new EventEmitter();

  public selectedUser = new BehaviorSubject<any>(null);

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private connectService: OidcSecurityService,
    private httpService: HttpService<any>
  ) {}

  authenticate(token: string, jsessionid: string, clientId: string, isConnect = false): Observable<boolean> {
    localStorage.setItem('clientId', clientId);
    this.setSessionCookie(jsessionid);
    if (isConnect) return this.authenticateConnect();
    return this.authenticatePedeFacil(token, clientId);
  }

  authenticateConnect(): Observable<boolean> {
    localStorage.setItem('isConnect', 'true');
    this.connectService.authorize();
    return new Observable((observable) => observable.next(false));
  }

  fetchAccessTokenConnect() {
    return this.connectService.checkAuth().pipe(
      mergeMap(({ accessToken }) => {
        this.httpService.setConnectAccessToken(accessToken);
        return this.updateUserAndCookie();
      })
    );
  }

  private setSessionCookie(jsessionid: string) {
    const domain = '.sodexobeneficios.com.br';
    this.cookieService.set('jsessionid', jsessionid, null as any, '/', domain, false, 'Lax');
  }

  authenticatePedeFacil(token: string, clientId: string): Observable<boolean> {
    localStorage.removeItem('isConnect');
    this.setTokenCookie(token);
    return this.updateUserAndCookie(clientId);
  }

  private setTokenCookie(token: string) {
    const domain = '.sodexobeneficios.com.br';
    if (token) {
      this.cookieService.set('Authorization', token, null as any, '/', domain, false, 'Lax');
    }
  }

  updateUserAndCookie(clientId?: string): Observable<boolean> {
    return this.userService.getCurrentUser(clientId).pipe(
      map((user) => {
        if (user) {
          this.updateUserDataInCookieAndEmit(user);
          return true;
        }
        return false;
      }),
      catchError((error) => {
        throw Error('Erro na chamada do servico');
      })
    );
  }

  updateUserDataInCookieAndEmit(user: User): void {
    const userStringified = JSON.stringify(user);
    localStorage.setItem('currentUser', userStringified);
    localStorage.setItem('user', userStringified);
    this.userStateChanged.emit(user);
  }

  isAuthenticated(): boolean {
    return !!(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== '');
  }

  logout() {
    this.doLogout() //
      .subscribe((c) => {
        this.onAfterLogout();
      });
  }

  onAfterLogout(): void {
    localStorage.clear();
    const jsessionid = this.cookieService.get('jsessionid');
    this.cookieService.deleteAll();
    window.location.href = this.logoutPageUrl + jsessionid;
  }

  doLogout(): Observable<any> {
    if (this.isConnect()) {
      this.connectService.logoffLocal();
    }
    return new Observable((observer) => {
      this.cookieService.delete('Authorization');
      observer.next();
      observer.complete();
    });
  }

  private isConnect() {
    return localStorage.getItem('isConnect');
  }

  getCurrentUser(): User {
    if (!this.isAuthenticated()) {
      const jsessionid = this.cookieService.get('jsessionid');
      window.location.href = this.logoutPageUrl + jsessionid;
    }
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

  getCurrentUserAsObservable(): Observable<User> {
    const currentUser = localStorage.getItem('currentUser');
    return currentUser
      ? new Observable((observer) => {
          const _user = JSON.parse(currentUser);
          observer.next(_user);
          observer.complete();
        })
      : this.userStateChanged;
  }

  redirectToPedeFacil() {
    window.location.href = this.getPedeFacilUrl();
  }

  getPedeFacilUrl() {
    const jsessionid = this.cookieService.get('jsessionid');
    return environment.logadoPedeFacilPageUrl + jsessionid;
  }

  getPedeFacilActionUrl(action: string, params: string = ''): string {
    const jsessionid = this.cookieService.get('jsessionid');
    return environment.basePedeFacilUrl + '/' + action + ';jsessionid=' + jsessionid + params;
  }

  redirectToPedeFacilAction(action: string, params: string = '') {
    window.location.href = this.getPedeFacilActionUrl(action, params);
  }

  setSelectedUser(user: any) {
    this.selectedUser.next(user);
    const userStringified = JSON.stringify(user);
    localStorage.setItem('currentUser', userStringified);
    window.location.reload();
  }

  getSelectedUserAsObservable() {
    return this.selectedUser.asObservable();
  }

  getLoggedUserAsObservable() {
    return of(this.getLoggedUser());
  }

  getLoggedUser(): User {
    return JSON.parse(localStorage.getItem('user')!) as User;
  }
}
