export enum HttpStatus {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  PRECONDITION_FAILED = 412,
  INTERNAL_SERVER_ERROR = 500,
  GATEWAY_TIMEOUT = 504
}
