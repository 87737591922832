import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { InputType } from '../../../../enums/input-type.enum';

@Component({
  selector: 'sdx-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent {
  inputType = InputType;

  @Input()
  name!: string;

  @Input()
  id!: string;

  @Input()
  placeholder!: string;

  @Input()
  maskFormat!: string;

  @Input()
  unmask!: boolean;

  @Input()
  className!: string;

  @Input()
  tableFilterClassName = 'table-filter__search';

  @Input()
  options!: any;

  @Output()
  private maskInputModelChanged: EventEmitter<any> = new EventEmitter();

  constructor(private cookieService: CookieService) {}

  maskInputModelChangedHandler(event: any): void {
    this.maskInputModelChanged.emit({ name: this.id, value: event });
  }

  dropdownChange(event: any) {
    const eventValue = event.value;
    this.maskInputModelChanged.emit({ name: 'companyOfficialDocument', value: eventValue });
    this.cookieService.set(
      'companyOfficialDocument',
      JSON.stringify(eventValue),
      undefined,
      '/',
      undefined,
      false,
      'Lax'
    );
  }
}
