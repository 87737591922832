import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { MessageToastService } from 'src/app/core/services/message.toast.service';
import { BeneficiaryService } from '../../../../core/services/beneficiary.service';
import { DeliveryPlaceService } from '../../../../core/services/delivery-place.service';
import { AddressesByZipCode } from '../../../dtos/addresses-by-zipcode.dto';
import { InputType } from '../../../enums/input-type.enum';
import { FederalUnit } from '../../../models/federal-unit.model';
import { AddressFormData } from './address-form-data.model';

@Component({
  selector: 'sdx-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  form!: FormGroup;
  address!: FormGroup;
  states: Array<FederalUnit>;
  addressFields = {
    disableNeighborhood: false,
    disableStreet: true,
    disableSharedFields: false,
  };
  zipCodeSubscription?: Subscription;
  inputType = InputType;

  @ViewChild('cepInput', { static: false })
  cepInput?: any;

  @Input()
  addressFormData?: AddressFormData;

  @Input()
  title?: string;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder,
    private deliveryPlaceService: DeliveryPlaceService,
    private beneficiaryService: BeneficiaryService,
    private spinner: NgxSpinnerService,
    private messageToastService: MessageToastService
  ) {
    this.states = this.deliveryPlaceService.getStates();
  }

  ngOnInit() {
    this.registerForm();
    this.registerCepInputListener();
  }

  ngAfterViewInit(): void {
    this.cepInput.focus;
  }

  ngOnDestroy() {
    if (this.zipCodeSubscription) {
      this.zipCodeSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyAddressParams();
  }

  private applyAddressParams() {
    if (this.addressFormData) {
      this.zipCode?.setValue(this.addressFormData.zipCode, { emitEvent: false });
      this.state?.setValue(this.addressFormData.state);
      this.city?.setValue(this.addressFormData.city);
      this.street?.setValue(this.addressFormData.street);
      this.number?.setValue(this.addressFormData.number);
      this.complement?.setValue(this.addressFormData.complement);
      this.neighborhood?.setValue(this.addressFormData.neighborhood);
    }
  }

  private registerForm() {
    this.address = this.formBuilder.group({
      zipCode: new FormControl('', [Validators.required, Validators.minLength(8)]),
      state: new FormControl('', [Validators.required, Validators.minLength(2)]),
      city: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(new RegExp('^[a-zA-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑZ0-9 ]+$')),
      ]),
      street: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(70)]),
      streetType: new FormControl('', []),
      number: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
        Validators.pattern(new RegExp('^[0-9]+(.?[0-9]+)?$')),
      ]),
      contactPhone: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      complement: new FormControl('', [Validators.maxLength(40)]),
      neighborhood: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
    });
    this.form = this.ctrlContainer.form;
    this.form.addControl('address', this.address);
  }

  private registerCepInputListener(): void {
    if (!this.zipCodeSubscription) {
      this.zipCodeSubscription = this.zipCode?.valueChanges //
        .subscribe((zipCodeValue: string) => {
          if (zipCodeValue && zipCodeValue.length === 8) {
            setTimeout(() => {
              zipCodeValue = zipCodeValue.replace('-', '');

              this.spinner.show();
              this.beneficiaryService
                .searchAddressByPostalCode(zipCodeValue) //
                .pipe(take(1)) //
                .subscribe(
                  (addresses: AddressesByZipCode) => {
                    this.setAddressValues(addresses);
                  },
                  (error) => {
                    this.spinner.hide();
                    this.messageToastService.showErrorMessage(
                      'Erro ao buscar CEP',
                      'Ocorreu um problema, tente novamente'
                    );
                  },
                  () => this.spinner.hide()
                );
            }, 750);
          } else if (!zipCodeValue || zipCodeValue.length === 0) {
            this.clearAddressValues();
          }
        });
    }
  }

  private setAddressValues(response: AddressesByZipCode): void {
    if (response.AddressList !== null && response.AddressList?.length! > 0) {
      const address = response.AddressList?.pop();
      this.addressFields.disableSharedFields = true;
      this.city?.setValue(address?.cityName);
      this.state?.setValue(this.deliveryPlaceService.getStateByFederationUnit(address?.federalUnit!)?.value);
      this.streetType?.setValue(address?.streetTypeAbbreviation);

      // There are some postal codes that doensn't provide the street and the neighborhood data. So we gotta handle it.
      if (this.hasStringValue(address?.streetName)) {
        this.street?.setValue(address?.streetName);
        this.addressFields.disableStreet = true;
      }

      if (this.hasStringValue(address?.quarterName)) {
        this.neighborhood?.setValue(address?.quarterName);
        this.addressFields.disableNeighborhood = true;
      }
    } else {
      // clear the fields
      this.clearAddressValues();
    }
  }

  private hasStringValue(field: any): boolean {
    return field && typeof field === 'string';
  }

  private clearAddressValues(): void {
    this.city?.setValue('');
    this.neighborhood?.setValue('');
    this.state?.setValue('');
    this.street?.setValue('');
    this.streetType?.setValue('');
    this.contactPhone?.setValue('');
    this.name?.setValue('');
    this.addressFields = {
      disableNeighborhood: false,
      disableStreet: false,
      disableSharedFields: false,
    };
  }

  get neighborhood() {
    return this.address?.get('neighborhood');
  }

  get zipCode() {
    return this.address?.get('zipCode');
  }

  get state() {
    return this.address?.get('state');
  }

  get city() {
    return this.address?.get('city');
  }

  get street() {
    return this.address?.get('street');
  }

  get number() {
    return this.address?.get('number');
  }
  get complement() {
    return this.address?.get('complement');
  }

  get streetType() {
    return this.address?.get('streetType');
  }

  get name() {
    return this.address?.get('name');
  }

  get contactPhone() {
    return this.address?.get('contactPhone');
  }

  setZipCode(event: any) {
    this.address?.patchValue({
      address: event,
    });
  }
}
