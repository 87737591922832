import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone) {}

  handleError(error: unknown): void {
    this.zone.run(() => {
      console.log(`Catch by GlobalErrorHandler -  ${error}`);
    });
  }
}
