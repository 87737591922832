<p-dialog
  [(visible)]="show"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: modalSize.toString() + 'vw', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  (onHide)="hideEvent()"
  (onShow)="showEvent()"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <ng-content select="header"></ng-content>
    <a
      class="link"
      role="button"
      tabindex="0"
      id="btn-exit-modal"
    >
      <span class="pi pi-times" (click)="hideEvent()"></span>
    </a>
  </ng-template>

  <ng-content select="body"></ng-content>

  <ng-template pTemplate="footer">
    <ng-content select="footer"></ng-content>
  </ng-template>
</p-dialog>
