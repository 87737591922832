export enum ColumnType {
  TEXT,
  STATE,
  DROPDOWN,
  ACTIONS,
  HTML,
  CAROUSEL,
  CHECKBOX,
  INPUT,
  IMG,
  RADIO
}
