import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { InputType } from '../../../../enums/input-type.enum';
import { TableColumnCarousel } from '../../models/table-column-carousel.model';
import { BeneficiaryStatus } from 'src/app/shared/enums/beneficiary-status.enum';
import { Benefit } from 'src/app/shared/models/benefit.model';

@Component({
  selector: 'sdx-table-column-carousel-content',
  templateUrl: './table-column-carousel-content.component.html',
  styleUrls: ['./table-column-carousel-content.component.scss'],
})
export class TableColumnCarouselContentComponent implements OnChanges, AfterViewChecked {
  inputType = InputType.ROUNDED;
  beneficiaryStatus = BeneficiaryStatus;

  @Input()
  column!: TableColumnCarousel<any>;

  @Input()
  columnsWidth!: number;

  @ViewChild('carouselContent', { static: false })
  carouselContent: any;

  @Input()
  content: any;

  @Input()
  id: any;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  value: any[] = [];

  form: FormGroup;

  constructor(private renderer: Renderer2, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      columnContent: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.buildFormArray();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['content'] && this.content?.benefits) {
      this.buildFormArray();
      this.setBenefitValueToColumn();
      this.emitValueChanged();
    }
  }

  get columnContent() {
    return this.form.controls['columnContent'] as FormArray;
  }

  ngAfterViewChecked() {
    this.calculateWidth();
  }

  setBenefitValueToColumn() {
    const benefitsWithCredit = this.content.benefits.filter((b: Benefit) => b.credit);
    benefitsWithCredit.forEach((benefit: Benefit) => {
      const control = this.columnContent.controls.find((c: any) => c.get('id').value === benefit.id);
      control?.setValue({ ...benefit, isInvalid: false });
    });
  }

  scrollTo(newPosition: any) {
    this.carouselContent.nativeElement.scrollTo(newPosition, 0);
  }

  private calculateWidth() {
    if (this.column && this.columnsWidth && this.carouselContent) {
      const baseWidth = 1200;
      const itemSize = 120;

      const widthRemaining = baseWidth - this.columnsWidth;
      const carouselWidth = this.column.items.length * itemSize;
      if (carouselWidth > widthRemaining) {
        const finalCarouselWidth = Math.floor(widthRemaining / itemSize) * itemSize;
        this.renderer.setStyle(this.carouselContent.nativeElement, 'max-width', `${finalCarouselWidth}px`);
      } else {
        this.renderer.setStyle(this.carouselContent.nativeElement, 'max-width', `${carouselWidth}px`);
      }
    }
  }

  inputChanged(event: any) {
    this.emitValueChanged();
  }

  emitValueChanged() {
    this.valueChanged.emit(this.columnContent.value);
  }

  buildFormArray() {
    if (this.columnContent.controls.length === 0) {
      this.column.items.forEach((c) => {
        this.columnContent.push(
          this.formBuilder.group({
            credit: [null],
            id: [c.productTypeCode],
            idProduct: [c.productTypeCode],
            isInvalid: [false],
          })
        );
      });
    }
  }
}
