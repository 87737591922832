import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule, DefaultLocalStorageService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.connectUrl,
        redirectUrl: environment.redirectUrl,
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        clientId: environment.clientId,
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        customParamsAuthRequest: {
          resource: environment.resource,
        },
        autoUserInfo: false,
        triggerAuthorizationResultEvent: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        disableRefreshIdTokenAuthTimeValidation: true,
        ignoreNonceAfterRefresh: true,
      },
    }),
  ],
  exports: [AuthModule],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService,
    },
  ],
})
export class AuthConfigModule {}
