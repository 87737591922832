<div #carouselHeader class="container" [ngStyle]="justifyContent()">
  <div class="item" *ngFor="let item of column.items">
    <div class="grid product-container">
      <div class="col-9 align-center container-product">
        <plx-icon [name]="item.iconName" [color]="item.color" class="product-image" size="xs"></plx-icon>
        <h5>{{ item.name }}</h5>
      </div>
      <div class="col-3 align-end" *ngIf="item.isRemovable">
        <img class="close" src="../../../../../../assets/icons/close_table_column.svg" (click)="removeColumn(item)" />
      </div>
    </div>
  </div>
</div>
