<ng-container *ngIf="config">
  <form [formGroup]="tableFormGroup">
    <p-table
      #table
      [columns]="config.headerColumns"
      [value]="config.datasource.data.records!"
      [sortOrder]="1"
      [sortField]="'name'"
      sortModel="single"
      [reorderableColumns]="true"
      [paginator]="true"
      [rows]="config.datasource.pageSize"
      [totalRecords]="config.datasource.data.totalRecords"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
      [ngClass]="{ 'table-fixed': type === tableType.FIXED }"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let column of columns; first as isFirst; let i = index">
            <th *ngIf="column.hasFilter" [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }">
              <div class="grid grid-nogutter justify-content-center align-items-center">
                <div class="col">
                  <sdx-table-filter
                    [id]="column.id"
                    [name]="column.headerTitle"
                    [formControlName]="column.id"
                    [placeholder]="column.placeholder"
                    [maskFormat]="column.maskFormat"
                    [options]="column.options"
                    (ngModelChange)="filter()"
                    (maskInputModelChanged)="maskInputModelChangedHadler($event)"
                    ngDefaultControl
                    [unmask]="!!column.maskFormat"
                  >
                  </sdx-table-filter>
                </div>

                <div *ngIf="column.hasOrder" class="col table__header-column-icon">
                  <sdx-table-column-sorter [sortByField]="column.id"> </sdx-table-column-sorter>
                </div>
                <div
                  *ngIf="column.actions && column.actions.length > 0"
                  class="col table__header-column-icon"
                  [ngClass]="{ neutral: type === tableType.FIXED }"
                >
                  <ng-container *ngFor="let item of column.actions">
                    <fa-icon (click)="item.action()" [icon]="item.iconName" size="2x"></fa-icon>
                  </ng-container>
                </div>
                <div
                  *ngIf="columns[i + 1] && columns[i + 1].type === columnType.CAROUSEL && enableCarousel"
                  class="col"
                >
                <plx-icon class="carousel-icon" name="chevron-left" size="xs" (click)="carouselScroll('left')"></plx-icon>
                </div>
              </div>
            </th>
            <th
              *ngIf="column && column.type === columnType.STATE"
              class="no-padding-top"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              <div class="grid grid-nogutter justify-content-center align-items-center">
                <div *ngIf="(column.headerState | columnState : column).showTooltip" class="col-12 text-end">
                  <img
                    src="../../../../../../assets/icons/help_icon.png"
                    [tooltip]="(column.headerState | columnState : column).tooltipText"
                    [position]="'left'"
                  />
                </div>
                <div
                  class="col-12 table__header-column-title"
                  [ngClass]="
                    (column.headerState | columnState : column).showTooltip
                      ? 'col-12 table__header-column-title_center'
                      : 'col-12 table__header-column-title'
                  "
                >
                  {{ column.headerTitle }}
                </div>
                <div class="fixed" *ngIf="(column.headerState | columnState : column).showHeaderIcon">
                  <img
                    [ngClass]="(column.headerState | columnState : column).iconClass"
                    (click)="handleHeaderColumnClick('status')"
                    class="table__column_checkable"
                    [src]="(column.headerState | columnState : column).icon"
                  />
                </div>
                <div
                  class="col table__header-column-title status-desc"
                  *ngIf="(column.headerState | columnState : column).headerText"
                >
                  {{ (column.headerState | columnState : column).headerText }}
                </div>
                <div *ngIf="column.hasOrder" class="fixed">
                  <sdx-table-column-sorter [sortByField]="'status'"> </sdx-table-column-sorter>
                </div>
              </div>
            </th>
            <th
              *ngIf="!column.hasFilter && column.type === columnType.TEXT"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              <div class="grid grid-nogutter">
                <div class="col justify-content-center table__header-column-title">{{ column.headerTitle }}</div>
                <div class="fixed align-items-end">
                  <sdx-table-column-sorter *ngIf="column.hasOrder" class="p-top-10" [sortByField]="column.id">
                  </sdx-table-column-sorter>
                </div>
              </div>
            </th>
            <th
              *ngIf="column.type === columnType.ACTIONS"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              {{ column.headerTitle }}
            </th>
            <th
              *ngIf="column.type === columnType.CHECKBOX || column.type === columnType.RADIO"
              ng-model="item.selected"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              <div class="col justify-content-center table__header-column-title">
                {{ setToFormGroup(column, 'headerState') }}
                <plx-checkbox                   
                (valueChange)="handleHeaderColumnClick('active')"
                [formControlName]="column.id"></plx-checkbox>
                {{ column.headerTitle }}
              </div>
            </th>
            <th
              *ngIf="column.type === columnType.INPUT"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              {{ column.headerTitle }}
            </th>

            <ng-container *ngIf="column.type === columnType.CAROUSEL">
              <th class="no-padding" [ngStyle]="{ width: carouselWidth > 0 ? carouselWidth + 'px' : 'auto' }">
                <div class="grid grid-nogutter align-items-center" style="flex-wrap: nowrap">
                  <div *ngIf="enableCarousel" class="align-items-center" style="position: absolute;">
                    <plx-icon name="chevron-left" (click)="carouselScroll('left')" class="carousel-icon" size="xs"></plx-icon>
                  </div>
                  <div class="col">
                    <sdx-table-column-carousel-header
                      [column]="column"
                      [columnsWidth]="columnsWidth"
                      [enabledCarousel]="enableCarousel"
                    ></sdx-table-column-carousel-header>
                  </div>
                </div>
              </th>
              <th *ngIf="enableCarousel">
                <plx-icon name="chevron-right" class="carousel-icon" (click)="carouselScroll('right')" size="xs"></plx-icon>
              </th>
            </ng-container>

            <th
              *ngIf="!column.hasFilter && column.type === columnType.IMG"
              [ngStyle]="{ width: column.width > 0 ? column.width + 'px' : 'auto' }"
            >
              <div class="col justify-content-center table__header-column-title">
                <img [src]="column.imgSrc" class="header__img" />
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
        <ng-container
          *ngIf="
            columns[columns.length - 1].type === columnType.CAROUSEL &&
            i % config.datasource.pageSize === 0 &&
            columns[columns.length - 1].actions
          "
        >
          <tr class="table__row table-row-carousel-actions">
            <td class="table__column no-padding" *ngFor="let column of columns">
              {{ column | json }}
              <ng-container *ngIf="column.items">
                <sdx-table-column-carousel-actions
                  [column]="column"
                  [columnsWidth]="columnsWidth"
                ></sdx-table-column-carousel-actions>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        <tr class="table__row" [ngClass]="{ 'table__row--disable': isRowDisable(rowData) }">
          <ng-container *ngFor="let column of columns; first as isFirst">
            <td
              class="table__column"
              *ngIf="column.type === columnType.STATE"
              (click)="handleColumnClick(column, rowData)"
            >
              <div class="grid grid-nogutter align-items-center justify-content-center">
                <div class="table__column_checkable">
                  <img [src]="(rowData[column.id] | columnState : column).icon" />
                </div>
                <div *ngIf="(rowData[column.id] | columnState : column).text" class="col status-desc">
                  {{ (rowData[column.id] | columnState : column).text }}
                </div>
              </div>
            </td>
            <td
              class="table__column"
              *ngIf="
                column.type === columnType.TEXT || column.type === columnType.DROPDOWN || column.type === columnType.IMG
              "
              (click)="handleColumnClick(column, rowData)"
            >
              <div class="grid grid-nogutter">
                <div
                  class="col tooltip"
                  [id]="'column-' + rowData[column.id]"
                  [ngClass]="{
                    danger:
                      rowData.orderStatus === 'Expirado' ||
                      rowData.orderStatus === 'Falha' ||
                      rowData.orderStatus === 'Pedido Rejeitado' ||
                      rowData.status === 'ORDER_ITEM_FAILED' ||
                      rowData.status === 'ORDER_ITEM_CANCELED',
                    cancelled: rowData.orderStatus === 'Cancelado'
                  }"
                  [ngStyle]="column.style"
                >
                  {{ column.format ? column.format(rowData[column.id]) : rowData[column.id] }}
                  <div *ngIf="rowData.orderStatus === 'Expirado' && column.headerTitle === 'Status'">
                    <span class="tooltiptext" id="info">
                      <img id="icon-info" src="assets/icons/info_icon.svg" />
                      {{ info }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td class="table__column" *ngIf="column.type === columnType.ACTIONS">
              <div class="grid grid-nogutter align-items-center justify-content-center">
                <div
                  class="table__column__action"
                  *ngFor="let action of column.actions"
                  (click)="handleClickAction(action, rowData)"
                  [id]="'column-' + column.headerTitle + '-' + i"
                  [ngClass]="{
                    danger:
                      rowData.orderStatus === 'Expirado' ||
                      rowData.orderStatus === 'Falha' ||
                      rowData.orderStatus === 'Pedido Rejeitado' ||
                      rowData.status === 'ORDER_ITEM_FAILED' ||
                      rowData.status === 'ORDER_ITEM_CANCELED',
                    cancelled: rowData.orderStatus === 'Cancelado'
                  }"
                >
                  <fa-icon
                    *ngIf="action.contentType === contentType.ICON && rowData.orderStatus !== 'Expirado'"
                    [icon]="action.iconName"
                    [ngClass]="action.iconClass"
                    size="lg"
                    [title]="action.name"
                  >
                  </fa-icon>
                  <img
                    *ngIf="action.contentType === contentType.IMG && rowData.orderStatus !== 'Expirado'"
                    [src]="action.imgSrc"
                  />
                  <span *ngIf="!action.justHint && rowData.orderStatus !== 'Expirado'">{{ action.name }}</span>
                  <span *ngIf="rowData.orderStatus === 'Expirado'" style="cursor: auto"> — </span>
                </div>
              </div>
            </td>
            <td class="table__column table__column__checkbox" *ngIf="column.type === columnType.CHECKBOX">
              <div class="grid grid-nogutter align-items-center justify-content-center">
                <div *ngFor="let action of column.actions">
                  {{ setToFormGroup(rowData, column.id) }}
                  <plx-checkbox      
                  [id]="i"
                  [name]="i"             
                  (valueChange)="handleCheckAction(action, rowData, $event)"
                  [checked]="tableFormGroup.get(column.id)?.value"
                  ngDefaultControl
                  ></plx-checkbox> 
                </div>
              </div>
            </td>
            <td class="table__column" *ngIf="column.type === columnType.INPUT">
              <div class="grid grid-nogutter align-items-center justify-content-center">
                <div
                  class="table__column__action"
                  *ngFor="let action of column.actions"
                  (click)="handleClickAction(action, rowData)"
                >
                  {{ setToFormGroup(rowData, column.id) }}
                  <plx-input
                  [name]="column.headerTitle"
                  [pTooltip]="rowData[column.id]"
                  [value]="rowData[column.id]"
                  tooltipPosition="top"
                  [disabled]="isRowDisable(rowData)"
                  [id]="'address-' + i"
                  ></plx-input>
                  <img
                    *ngIf="action.contentType === contentType.IMG"
                    [src]="action.imgSrc"
                    class="p-left"
                    [id]="'btn-' + i"
                  />
                </div>
              </div>
            </td>
            <td class="table__column no-padding" *ngIf="column.type === columnType.CAROUSEL">
              <sdx-table-column-carousel-content
                [column]="column"
                [columnsWidth]="columnsWidth"
                [content]="rowData"
                (valueChanged)="handleColumnChange(column, rowData, $event)"
                [id]="'beneficiary-' + rowData.beneficiaryId + '-' + 'wallet-' + i"
              ></sdx-table-column-carousel-content>
            </td>
            <td class="table__column table__column__checkbox" *ngIf="column.type === columnType.RADIO">
              <div class="grid grid-nogutter align-items-center justify-content-center">
                <div class="col text-center" *ngIf="rowData[column.id]">
                  {{ setToFormGroup(rowData, column.id) }}
                  <p-radioButton
                    [name]="rowIndex"
                    [value]="rowData[column.id]"
                    [formControlName]="column.id"
                  ></p-radioButton>
                </div>
                <div *ngIf="!rowData[column.id]">-</div>
              </div>
            </td>
          </ng-container>
        </tr>
        <ng-container *ngIf="getSubrecord(rowData); let subrecord">
          <tr
            class="table__row table__row--subrecord"
            [ngClass]="{ 'table__row--disable': isRowDisable(rowData) }"
            *ngFor="let data of subrecord"
          >
            <!-- <td class="table__column"></td> -->
            <ng-container *ngFor="let column of columns">
              <td
                class="table__column"
                *ngIf="column.type === columnType.STATE"
                (click)="handleColumnClick(column, data)"
              >
                <div class="grid grid-nogutter align-items-center">
                  <div class="fixed table__column_checkable">
                    <fa-icon
                      [ngClass]="(data[column.id] | columnState : column).iconClass"
                      [icon]="(data[column.id] | columnState : column).icon"
                    ></fa-icon>
                  </div>
                  <div class="col status-desc">
                    {{ (data[column.id] | columnState : column).text }}
                  </div>
                </div>
              </td>
              <td
                class="table__column"
                *ngIf="column.type === columnType.TEXT || column.type === columnType.DROPDOWN"
                (click)="handleColumnClick(column, data)"
              >
                {{ column.format ? column.format(data[column.id]) : data[column.id] }}
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <td class="table__column no-padding">
              <div *ngIf="col.type === columnType.CAROUSEL">
                <sdx-table-column-carousel-footer
                  [column]="col"
                  [columnsWidth]="columnsWidth"
                  [enabledCarousel]="enableCarousel"
                  [total]="config.datasource.data.columnsTotals"
                ></sdx-table-column-carousel-footer>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns class="empty-message">
        <tr class="empty-message">
          <td [attr.colspan]="columns.length">
            <div *ngFor="let message of config.datasource.data.emptyMessage">
              {{ message }}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template  pTemplate="paginatorright" class="table__total-records">
        <label>{{ config.datasource.data.selectedRecords }}</label>
        <plx-button *ngIf="config.cleanButton && config.cleanButton.label" (click)="handleButtonClick($event)" variant="primary-outlined" size="lg" [label]="config.cleanButton.label" class="clean-button" ></plx-button>
      </ng-template>
    </p-table>
  </form>
</ng-container>
