import { PipeTransform, Pipe } from '@angular/core';
import { TableColumnState } from '../models/table-column-state.model';

@Pipe({ name: 'columnState' })
export class ColumnStatePipe<T> implements PipeTransform {
  transform(value: any, column: TableColumnState<T>): any {
    const columnState = column.states?.filter(c => c.value === value)[0];
    return columnState ? columnState : new TableColumnState();
  }
}
