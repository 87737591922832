const baseVTUrl = 'https://sodexo-qa.appspot.com/';
const loginVTUrl = baseVTUrl + 'api/sso/auth?partner=sodexo&redirect=true&token=';
const basePFPageUrl = 'https://desenvpedefacil.sodexobeneficios.com.br/PPW/';
const logoutPFPageUrl = '/security/j_logout;jsessionid=';
const logadoPFPageUrl = '/security/authentication.do;jsessionid=';
const nfeUrl = 'https://testeeiss.barueri.sp.gov.br/nfe';

export const environment = {
  production: false,
  baseApiUrl: 'https://api-pb-dev.sodexobeneficios.com.br',
  environmentName: 'dev',
  baseLoadBalancerUrl: 'http://pb-be-cb-load-balancer-678114457.us-east-1.elb.amazonaws.com:8081',
  basePedeFacilUrl: basePFPageUrl,
  logoutPedeFacilPageUrl: basePFPageUrl + logoutPFPageUrl,
  logadoPedeFacilPageUrl: basePFPageUrl + logadoPFPageUrl,
  loginVTUrl,
  acessoVt: baseVTUrl + 'acesso',
  nfeUrl,
  connectUrl: 'https://connect.uat.pluxee.app/op',
  redirectUrl: 'https://desenv-compre.sodexobeneficios.com.br/auth/cb',
  postLogoutRedirectUri: 'https://homolog-portal.sodexobeneficios.com.br/empresas/pede-facil/',
  clientId: 'bfa11c6f-1f3d-4b47-bf47-6316ba7cfa91',
  resource: 'https://api-pb-dev.sodexobeneficios.com.br/',
};
