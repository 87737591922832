import { Component, Input, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { TableColumnCarousel } from '../../models/table-column-carousel.model';

@Component({
  selector: 'sdx-table-column-carousel-header',
  templateUrl: './table-column-carousel-header.component.html',
  styleUrls: ['./table-column-carousel-header.component.scss']
})
export class TableColumnCarouselHeaderComponent implements AfterViewChecked {
  @Input()
  column!: TableColumnCarousel<any>;

  @Input()
  columnsWidth!: number;

  @ViewChild('carouselHeader', { static: false })
  private carouselHeader: any;

  @Input()
  enabledCarousel!: Boolean;

  constructor(private renderer: Renderer2) {}

  ngAfterViewChecked() {
    this.calculateWidth();
  }

  justifyContent() {
    return this.enabledCarousel ? { 'justify-content': 'flex-start' } : { 'justify-content': 'center' };
  }

  scrollTo(newPosition: any) {
    this.carouselHeader.nativeElement.scrollTo(newPosition, 0);
  }

  removeColumn(item: any) {
    this.column.removeItem(item.name);
  }

  private calculateWidth() {
    if (this.column && this.columnsWidth && this.carouselHeader) {
      const baseWidth = 1200;
      const itemSize = 120;

      const widthRemaining = baseWidth - this.columnsWidth;
      const carouselWidth = this.column.items.length * itemSize;
      if (carouselWidth > widthRemaining) {
        const finalCarouselWidth = Math.floor(widthRemaining / itemSize) * itemSize;
        this.renderer.setStyle(this.carouselHeader.nativeElement, 'max-width', `${finalCarouselWidth}px`);
      } else {
        this.renderer.setStyle(this.carouselHeader.nativeElement, 'max-width', `${carouselWidth}px`);
      }
    }
  }
}
