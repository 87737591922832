<div
  class="tooltip"
  [ngClass]="['tooltip--' + position]"
  [class.tooltip--visible]="visible"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
>
  <span class="tooltip-text" id="info">
    <plx-icon name="info" color="white"></plx-icon>
    {{ tooltip }}
  </span>
</div>
