import { AfterContentInit, AfterViewChecked, Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CnpjPipe, CpfPipe } from 'ng2-brpipes';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Menubar } from 'primeng/menubar';
import { Company, User } from 'src/app/shared/models/user.model';
import { AuthGuard } from '../../guards/auth-guard.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'sdx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterContentInit, OnDestroy, AfterViewChecked {
  private routerIsActive$: any;
  menuItems: MenuItem[] = [];
  user!: User;

  @ViewChild('menubar', { static: false })
  menuBarRef!: Menubar;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private authService: AuthService,
    private authGuardService: AuthGuard,
    private cnpjPipe: CnpjPipe,
    private confirmationService: ConfirmationService,
    private cpfPipe: CpfPipe
  ) {}

  ngAfterContentInit() {
    this.populateMenu();
    this.fetchAllUsers();
    this.subscribeRouterActivated();
    this.subscribeUserCompanySubject();
  }

  private fetchAllUsers() {
    this.authService.getLoggedUserAsObservable().subscribe((response) => {
      if (response.isWholesale) {
        this.updateWholesaleMenu(response.companies);
      }
    });
  }

  updateWholesaleMenu(companies: Company[]) {
    const loginItem = this.findMenuItemById('login');
    if (loginItem) {
      const wholesaleItems = companies.map((user: any) => this.createWholesaleMenuItem(user));
      loginItem.items = wholesaleItems;
    }
  }

  createWholesaleMenuItem(user: User | Company) {
    const documentNumber = this.transformDocumentNumber(user.documentNumber);

    return {
      label: `${user.name} \n ${documentNumber}`,
      id: user.cdAraras,
      tooltipOptions: {
        tooltipLabel: `${user.name} \n  ${documentNumber} \n ClienteId: ${user.cdAraras}`,
        tooltipPosition: 'left',
      },
      command: () => {
        this.showConfirmDialog(user);
      },
    };
  }

  findMenuItemById(id: string) {
    return this.menuItems.find((item) => item.id === id);
  }

  ngOnDestroy() {
    this.unsubscribeRouterActivated();
  }

  ngAfterViewChecked() {
    this.createLoginContent();
  }

  goToProductsPage() {
    this.router.navigate(['home/']);
  }

  private populateMenu() {
    this.menuItems = [];

    this.menuItems.push(
      {
        label: 'Pedidos',
        id: 'pedidos',
        items: [
          {
            label: 'Pedido em tela',
            id: 'pedido-em-tela',
            command: () => {
              this.router.navigate(['order']);
            },
          },
          {
            label: 'Pedido por planilha',
            id: 'pedido-por-planilha',
            command: () => {
              this.router.navigate(['bulk-order']);
            },
          },
          {
            label: 'Histórico de pedidos',
            id: 'historico-pedidos',
            command: () => {
              this.router.navigate(['orders']);
            },
          },
        ],
      },
      {
        label: 'Beneficiários',
        id: 'beneficiarios',
        items: [
          {
            label: 'Consulta de Beneficiários',
            id: 'consulta-beneficiarios',
            command: () => {
              this.router.navigate(['beneficiary']);
            },
          },
          {
            label: 'Adicionar Beneficiário',
            id: 'adicionar-beneficiario',
            command: () => {
              this.router.navigate(['beneficiary'], {
                queryParams: {
                  addBeneficiary: true,
                },
              });
            },
          },
        ],
      },
      {
        label: 'Financeiro',
        id: 'financeiro',
        items: [
          {
            label: 'Informe de Rendimentos',
            id: 'informe-de-rendimentos',
            command: () => {
              this.router.navigate(['income-report']);
            },
          },
        ],
      },
      {
        label: 'Relatórios',
        id: 'Relatorios',
        items: [
          {
            label: 'Relatórios de Entrega',
            id: 'relatórios',
            command: () => {
              this.router.navigate(['delivery-report']);
            },
          },
        ],
      },
      {
        label: 'Gestão de Carteiras',
        id: 'gestao-carteiras',
        items: [
          {
            label: 'Configurar Carteiras',
            id: 'configurar-carteiras',
            command: () => {
              this.router.navigate(['wallet-maneger']);
            },
          },
        ],
      },
      {
        label: '',
        id: 'login',
      }
    );
  }

  private createLoginContent(userInfo?: any) {
    if (this.menuBarRef) {
      const loginEl = this.renderer.selectRootElement('#login>span', true);
      if (!this.hasLoginInfo(loginEl) || userInfo) {
        this.createLoginInfo(loginEl);
      }
    }
  }

  private hasLoginInfo(loginSpan: any) {
    return loginSpan.textContent !== '';
  }

  private showConfirmDialog(user: any) {
    if (user) {
      this.confirm(user);
    }
  }

  confirm(user: any) {
    this.confirmationService.confirm({
      header: 'Atenção!',
      message: 'Deseja alterar CNPJ?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.confirmModal(user);
      },
      reject: () => {
        return;
      },
    });
  }

  confirmModal(user: any) {
    this.authService.setSelectedUser(user);
  }

  private createLoginInfo(loginEl: any) {
    if (this.hasLoginInfo(loginEl)) {
      loginEl.textContent = '';
      this.user = this.authService.getCurrentUser();
    }

    const loggedUser = this.authService.getLoggedUser();

    this.renderer.addClass(loginEl, 'text-right');
    this.renderer.setStyle(loginEl, 'border-left', '1px solid #cfd0de');
    this.renderer.setStyle(loginEl, 'pointer-events', 'none');

    const login = this.renderer.createText(`Login: ${loggedUser.email}`);
    const userName = this.renderer.createText(this.user.name);
    const document = this.renderer.createText(`${this.transformDocumentNumber(this.user.documentNumber)}`);
    const lineBreak = this.renderer.createElement('br');
    const lineBreak2 = this.renderer.createElement('br');

    this.renderer.appendChild(loginEl, login);
    this.renderer.appendChild(loginEl, lineBreak);
    this.renderer.appendChild(loginEl, userName);
    this.renderer.appendChild(loginEl, lineBreak2);
    this.renderer.appendChild(loginEl, document);
  }

  private subscribeRouterActivated() {
    this.routerIsActive$ = this.authGuardService.routerIsActive().subscribe((active) => {
      if (active) {
        this.user = this.authService.getCurrentUser();
      }
    });
  }

  private unsubscribeRouterActivated() {
    if (this.routerIsActive$) {
      this.routerIsActive$.unsubscribe();
    }
  }

  transformDocumentNumber(document: any) {
    if (document.length > 11) {
      return `CNPJ: ${this.cnpjPipe.transform(document)}`;
    } else {
      return `CPF: ${this.cpfPipe.transform(document)}`;
    }
  }
  private subscribeUserCompanySubject() {
    this.authService.getLoggedUserAsObservable().subscribe((list) => {
      if (list) {
        this.createLoginContent(list);
      }
    });
  }
}
