<form [formGroup]="address">
  <section>
    <div class="grid grid-nogutter">
      <div *ngIf="title" class="col-12 title text-center">
        {{ title }}
      </div>

      <div class="col-6">
        <span class="ui-float-label">
          <plx-input
          [id]="'zipCode'"
          formControlName="zipCode"
          [mask]="'99999-999'"
          [unmask]="true"
          [required]="true"
          [label]="'CEP'"
          [placeholder]="'00000-000'"
          [invalid]="zipCode?.invalid && zipCode?.dirty"
          [errormessage]="
          zipCode?.errors?.['required'] ? 'CEP é um campo obrigatório' : 
          zipCode?.errors?.['minlength'] ? 'CEP deve possuir no mínimo 8 caracteres' : '' "
          #cepInput
          ></plx-input>
        </span>
      </div>
      <div class="col-6">
        <span class="ui-float-label">
          <plx-select
          [label]="'Estado'"
          [placeholder]="'Selecionar'"
          [options]="states"
          [disabled]="addressFields.disableSharedFields"
          formControlName="state"
          ngDefaultControl
          [required]="true"
          ></plx-select>
        </span>
        <div class="danger-text">
          <div *ngIf="state?.invalid && state?.dirty && state?.errors?.['required']">Estado é um campo obrigatório</div>
        </div>
      </div>
      <div class="col-6">
        <span class="ui-float-label">
          <plx-input
          [id]="'city'"
          [label]="'Cidade'"
          formControlName="city"
          [disabled]="true"
          #enabledCityInput
          *ngIf="addressFields.disableSharedFields; else disabledCityInput"
          [placeholder]="'Cidade'"
          [required]="true"
          [invalid]="city?.invalid && city?.dirty"
          [errormessage]="city?.errors?.['required'] ? 'Cidade é um campo obrigatório' : 
          city?.errors?.['pattern'] ? 'Cidade não deve ter caracteres especiais ': 
          city?.errors?.['minlength'] ? 'Cidade deve possuir no mínimo 2 caracteres': ''"
          ></plx-input>

          <ng-template #disabledCityInput>
            <plx-input
            [id]="'city'"
            formControlName="city"
            [label]="'Cidade'"
            [placeholder]="'Cidade'"
            [required]="true"
            [invalid]="city?.invalid && city?.dirty"
            [errormessage]="city?.errors?.['required'] ? 'Cidade é um campo obrigatório' : 
            city?.errors?.['pattern'] ? 'Cidade não deve ter caracteres especiais ': 
            city?.errors?.['minlength'] ? 'Cidade deve possuir no mínimo 2 caracteres': ''"
            >
            </plx-input>
          </ng-template>
        </span>
      </div>
      <div class="col-6">
        <span class="ui-float-label">
          <plx-input
          [id]="'neighborhood'"
          formControlName="neighborhood"
          [label]="'Bairro'"
          [required]="true"
          [placeholder]="'Bairro'"
          [invalid]="neighborhood?.invalid && neighborhood?.dirty"
          [errormessage]="neighborhood?.errors?.['required'] ? 'Bairro é um campo obrigatório':
          neighborhood?.errors?.['minlength'] ? 'Bairro deve possuir no mínimo 5 caracteres' : 
          neighborhood?.errors?.['maxlength'] ? 'Bairro deve possuir no máximo 40 caracteres' : ''"
          >
          </plx-input>
        </span>
      </div>
      <div class="col-12">
        <span class="ui-float-label">
          <plx-input           
          [id]="'street'"
          formControlName="street"
          [label]="'Endereço'"
          [placeholder]="'Endereço'"
          [required]="true"
          [invalid]="street?.invalid && street?.dirty"
          [errormessage]="
          street?.errors?.['required'] ? 'Endereço é um campo obrigatório' :
          street?.errors?.['minlength'] ? 'Endereço deve possuir no mínimo 5 caracteres' :
          street?.errors?.['maxlength'] ? 'Endereço deve possuir no máximo 40 caracteres' : ''">
          </plx-input>
        </span>
      </div>

      <div class="col-6">
        <span class="ui-float-label">
          <plx-input
          [id]="'number'"
          formControlName="number"
          [label]="'Número'"
          [placeholder]="'Número'"
          [invalid]="number?.invalid && number?.dirty"
          [errormessage]="
          number?.errors?.['required'] ? 'Número é um campo obrigatório' :
          number?.errors?.['maxlength'] ? 'Número deve possuir no máximo 10 dígitos' :
          number?.errors?.['pattern'] ? 'Número deve possuir valores numéricos' : ''
          "
          [required]="true"
          ></plx-input>
        </span>
      </div>

      <div class="col-6">
        <span class="ui-float-label">
          <plx-input 
          [id]="'complement'"
          formControlName="complement"
          [label]="'Complemento'"
          [placeholder]="'Complemento'"
          [invalid]="complement?.invalid && complement?.dirty"
          [errormessage]=" complement?.errors?.['maxlength'] && 'Complemento deve possuir no máximo 40 caracteres'"
          >
          </plx-input>
        </span>
      </div>
      <div class="col-6">
        <span class="ui-float-label">
          <plx-input             
          [label]="'Responsável pelo recebimento'"
          [id]="'name'"
          [placeholder]="'Colaborador'"
          [required]="true"
          [maxlength]="30"
          formControlName="name"
          [invalid]="name?.invalid && name?.dirty"
          [errormessage]="(name?.errors?.['hasSpecialCharacter'] && 'Nome deve conter apenas letras.') 
          || (name?.errors?.['required'] && 'Nome é um campo obrigatório.') || (name?.errors?.['maxlength'] && 'Nome deve possuir no máximo 40 caracteres')"
          ngDefaultControl>
        </plx-input>
        </span>
      </div>
      <div class="col-6">
        <span class="ui-float-label">
          <plx-input
          [mask]="'(99) 9 9999 9999'"
          [unmask]="true"
          [label]="'Telefone do responsável'"
          [id]="'phone'"
          [placeholder]="'(00) 000-000-000'"
          [required]="true"
          formControlName="contactPhone"
          ngDefaultControl
          [invalid]="contactPhone?.invalid && contactPhone?.dirty"
          [errormessage]="(contactPhone?.errors?.['required'] && 'Telefone é um campo obrigatório.') ||
          (contactPhone?.errors?.['phoneInvalid'] && 'Informe um telefone válido.')"
          ></plx-input>
        </span>
      </div>
    </div>
  </section>
</form>
