import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sdx-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnChanges {
  @Input() timeline!: Array<any>;

  items!: MenuItem[];
  indexAtivo!: number;
  selectedIndex: any;
  failed: boolean = false;
  label: any;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.timeline) {
      this.failed = this.timeline.map((f) => f.value).includes('FAILED');
    }
  }

  ngOnInit() {}
}
