

<form [formGroup]="form">
  <div #carouselContent class="container" formArrayName="columnContent">
    <div class="item" *ngFor="let contentForm of columnContent.controls; let i = index">
      <div style="position: inherit" [formGroupName]="i">
        <plx-input
        [id]="id" 
        name="Valor" 
        formControlName="credit" 
        [maxlength]="12"
        [disabled]="content.status === beneficiaryStatus.INACTIVE"
        placeholder="R$ 0,00"
        [currencyCode]="'R$ '"
        [currencyMask]="true"     
        [unmask]="true"
        (ngModelChange)="inputChanged($event)"  
        >
        </plx-input>
      </div>
    </div>
  </div>
</form>