import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { BeneficiaryStatus } from 'src/app/shared/enums/beneficiary-status.enum';
import { ColumnType } from '../../enums/column-type.enum';
import { TableType } from '../../enums/table-type.enum';
import { ColumnAction } from '../../models/column-action.model';
import { TableColumnCarousel } from '../../models/table-column-carousel.model';
import { TableColumn } from '../../models/table-column.model';
import { TableConfig } from '../../models/table-config.model';
import { TableData } from '../../models/table-data.model';
// tslint:disable-next-line:max-line-length
import { TableColumnCarouselHeaderComponent } from '../table-column-carousel-header/table-column-carousel-header.component';
// tslint:disable-next-line:max-line-length
import { TableColumnCarouselActionsComponent } from '../table-column-carousel-actions/table-column-carousel-actions.component';
// tslint:disable-next-line:max-line-length
import { Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { ContentType } from '../../enums/content-type.enum';
import { TableColumnCarouselContentComponent } from '../table-column-carousel-content/table-column-carousel-content.component';
import { TableColumnCarouselFooterComponent } from '../table-column-carousel-footer/table-column-carousel-footer.component';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sdx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  nameSearch: string = '';
  readonly columnType = ColumnType;
  readonly tableType = TableType;
  readonly contentType = ContentType;
  tableFormGroup!: FormGroup;
  info = 'O prazo de pagamento expirou e seu pedido foi cancelado. Por favor faça um novo pedido.';
  filterChanged = new Subject<any>();
  filters: any = {};
  columnsWidth = 0;
  carouselWidth = 0;
  carouselQuantityColumns = 0;
  enableCarousel = false;
  form!: FormGroup;
  rowIndex!: any;
  selectedRecords = '';

  @Input()
  type: TableType = TableType.STANDARD;

  @Input()
  config!: TableConfig<any>;

  @Output()
  onReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  afterLoadData: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @ViewChild(TableColumnCarouselHeaderComponent, { static: false })
  tableColumnCarouselHeader: any;

  @ViewChild(TableColumnCarouselActionsComponent, { static: false })
  tableColumnCarouselActions: any;

  @ViewChildren(TableColumnCarouselContentComponent)
  tableColumnCarouselContentList?: QueryList<TableColumnCarouselContentComponent>;

  @ViewChildren(TableColumnCarouselFooterComponent)
  tableColumnCarouselFooterList?: QueryList<TableColumnCarouselFooterComponent>;

  constructor(
    private spinner: NgxSpinnerService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.refreshControls();
    this.registerFilterChanged();

    this.form = this.formBuilder.group({
      columnContent: this.formBuilder.array([]),
    });
  }

  get columnContent() {
    return this.form.get('columnContent') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['config'].currentValue) {
      this.updateCarouselQuantityColumns();
      this.refreshControls();
      this.resizeColumns();
    }
  }

  maskInputModelChangedHadler(event: any): void {
    if (event && event.name) {
      this.tableFormGroup.get(event.name)?.setValue(event.value);
      if (event.value && event.value.detail.length > 0) {
        this.filter();
      }
    }
  }

  ngOnDestroy() {
    this.filterChanged.unsubscribe();
  }

  onLazyLoad(event: any) {
    const nextPage: number = event.first / event.rows + 1;
    this.config.datasource.page = nextPage;
    this.loadData(nextPage, event.rows, this.getFilters());
  }

  filter() {
    const firstTime = this.filters === undefined;
    let hasChanged = false;

    if (firstTime) {
      this.filters = this.tableFormGroup.getRawValue();
      hasChanged = true;
    } else {
      const keys: any = Object.keys(this.filters);
      for (const i in keys) {
        if (this.filters.hasOwnProperty(keys[i])) {
          if (keys[i] !== null || keys[i] !== undefined) {
            const key = keys[i];

            if (!(this.filters[key] === this.tableFormGroup.getRawValue()[key])) {
              hasChanged = true;
              break;
            }
          }
        }
      }

      if (hasChanged) {
        this.filters = this.tableFormGroup.getRawValue();
      }
    }

    let tableFormData = this.tableFormGroup.getRawValue();
    let filterdData = {
      ...tableFormData,
      cpf: tableFormData.cpf.replace(/[^\d]+/g, ''),
    };
    this.filterChanged.next({ filters: filterdData, hasChanged });
  }

  refresh(filters?: any) {
    if (this.config) {
      this.loadData(this.config.datasource.page, this.config.datasource.pageSize, filters);
    }
  }

  clearData() {
    if (this.config) {
      this.config.datasource.data.clearData();
    }
  }

  registerFilterChanged() {
    this.filterChanged.pipe(startWith(''), debounceTime(400), distinctUntilChanged()).subscribe((params) => {
      this.config.datasource.page = params.hasChanged ? 1 : this.config.datasource.page;
      this.refresh(params.filters);
    });
  }

  handleColumnClick(column: TableColumn<any>, rowData: any): void {
    if (column.events) {
      if (rowData.orderStatus == 'Pedido Rejeitado') {
        this.router.navigate([`/order-error/${rowData.orderNumber}`]);
      } else {
        column.events.executeHandleClick(rowData);
      }
    }
  }

  handleColumnChange(column: TableColumn<any>, rowData: any, event: any): void {
    //FIXME: ajustar erro
    // @ts-ignore
    if (column.events && column.events.handleChange) {
      column.events.executeHandleChange(rowData, event);
    }
  }

  handleHeaderColumnClick(columnId: string) {
    const column: TableColumn<any> = this.config.headerColumns.filter((c: TableColumn<any>) => c.id === columnId)[0];
    //FIXME: ajustar erro
    // @ts-ignore
    if (column.events && column.events.handleHeaderClick) {
      column.events.executeHeaderClick(column, this.config.datasource);
    }
  }

  handleClickAction(action: ColumnAction, rowData: any) {
    action.action?.(rowData);
  }

  getTableRowNumber(index: any) {
    return index + 1;
  }

  setToFormGroup(rowData: any, columnId: any) {
    this.tableFormGroup.get(columnId)?.setValue(rowData[columnId]);
  }

  // TODO Desacoplar esta função da table
  isRowDisable(rowData: any) {
    return rowData.status === BeneficiaryStatus.INACTIVE;
  }

  getSubrecord(rowData: any) {
    return rowData[this.config.subrecordProperty];
  }

  private loadData(page: number = 1, pageSize: number = 10, filter: object) {
    if (this.isFilterEmptyOrNull(filter) && this.recordsIsCached(page)) {
      this.config.datasource.data.loadCachedRecords(page);
      this.ref.detectChanges();
    } else {
      this.loadDataFromService(page, pageSize, filter);
    }
  }

  private createCacheKey(page: number, filter: object) {
    if (!filter) return;
    const filterResult = Object.entries(filter).filter(([key, value]) => value);
    if (filterResult) return `page:${page}` + ':' + filterResult.map(([key, value]) => key + ':' + value).join('-');
    return `page:${page}`;
  }

  private isFilterEmptyOrNull(filter?: object) {
    if (filter) {
      return Object.values(filter).every((o) => o === null || o === '');
    }
    return filter === undefined || filter === null;
  }

  private loadDataFromService(page: number, pageSize: number, filter: object) {
    if (this.config) {
      this.spinner.show();
      const isFirstPage = page === 1;
      this.config.datasource.page = page;
      this.config.datasource
        .loadData(filter, page, pageSize)
        .pipe(take(1))
        .subscribe({
          next: (tableData: TableData) => {
            const records = tableData ? tableData.records : [];
            const selectedRecords = this.config.datasource.data.selectedRecords;
            this.config.datasource.data.totalRecords = tableData ? tableData.totalRecords : 0;
            this.config.datasource.data.setSelectedRecords(
              selectedRecords && selectedRecords !== ''
                ? selectedRecords
                : `${tableData.totalRecords} de ${tableData.totalRecords} selecionados`
            );
            const cacheKey = this.createCacheKey(page, filter);
            //FIXME: ajustar erro
            // @ts-ignore
            this.config.datasource.data.setRecordsPerPage(cacheKey, records);

            if (isFirstPage) {
              this.onReady.emit();
            }

            this.afterLoadData.emit(this.config.datasource.data.getRecords(page));
            this.spinner.hide();
            this.ref.detectChanges();
          },
          complete: () => this.spinner.hide(),
        });
    }
  }

  private recordsIsCached(page: number) {
    if (this.config && this.config.datasource && this.config.datasource.data) {
      return this.config.datasource.data.hasRecords(page);
    }
    return false;
  }

  private refreshControls() {
    if (!this.config) {
      return;
    }

    const formControls = {};
    this.config.headerColumns
      //.filter(c => c.hasFilter)
      .forEach((column) => {
        const defaultValue = column.defaultValue ? column.defaultValue : '';
        //FIXME: ajustar erro
        // @ts-ignore
        formControls[column.id] = new FormControl(defaultValue);
      });
    this.tableFormGroup = new FormGroup(formControls);
  }

  private resizeColumns() {
    const baseWidth = 1200;
    const itemSize = 120;
    if (this.carouselQuantityColumns > 0) {
      this.columnsWidth = this.config.headerColumns.reduce((previus, column) => {
        if (column && column.width) {
          return previus + column.width;
        }
        return previus;
      }, 0);

      const widthRemaining = baseWidth - this.columnsWidth;
      this.carouselWidth = this.carouselQuantityColumns * itemSize;
      if (this.carouselWidth > widthRemaining) {
        this.carouselWidth = Math.floor(widthRemaining / itemSize) * itemSize;
        this.enableCarousel = true;
      } else {
        this.enableCarousel = false;
      }

      const firstColumn = this.config.headerColumns[0];

      //FIXME: ajustar erro
      // @ts-ignore
      if (firstColumn.width > 50) {
        // @ts-ignore
        firstColumn.width = baseWidth - this.carouselWidth - this.columnsWidth + firstColumn.width;
      }
    } else {
      this.carouselWidth = 0;
    }
  }

  private updateCarouselQuantityColumns() {
    const carouselColumn = this.config.headerColumns.filter(
      (c) => c.type === ColumnType.CAROUSEL
    )[0] as TableColumnCarousel<any>;

    if (carouselColumn && carouselColumn.items) {
      this.carouselQuantityColumns = carouselColumn.items.length;
    }
  }

  carouselScroll(direction: string, event?: Event) {
    const itemSize = 120;
    const scrollPostion = this.tableColumnCarouselHeader.carouselHeader.nativeElement.scrollLeft;

    let newPosition: any;
    if (direction === 'left') {
      newPosition = scrollPostion - itemSize;
    } else if (direction === 'right') {
      newPosition = scrollPostion + itemSize;
    }

    this.tableColumnCarouselHeader.scrollTo(newPosition);

    this.tableColumnCarouselContentList?.forEach((carouselContentComponent) =>
      carouselContentComponent.scrollTo(newPosition)
    );
    this.tableColumnCarouselFooterList?.forEach((carouselFooterComponent) =>
      carouselFooterComponent.scrollTo(newPosition)
    );
  }

  isTableEmpty() {
    return this.config.datasource.data.totalRecords === 0;
  }

  handleCheckAction(action: ColumnAction, rowData: any, event: any) {
    action.action?.(event.detail, rowData);
  }

  handleLoadAction(action: ColumnAction, rowData: any) {
    action.action?.(rowData);
  }

  private getFilters() {
    let filterFormGroup: FormGroup;
    const formControls = {};

    this.config.headerColumns
      .filter((c) => c.hasFilter)
      .forEach((column) => {
        const defaultValue = column.defaultValue ? column.defaultValue : '';
        //FIXME: ajustar erro
        // @ts-ignore
        formControls[column.id] = new FormControl(defaultValue);
      });
    filterFormGroup = new FormGroup(formControls);
    return filterFormGroup.getRawValue();
  }

  handleButtonClick(event: any) {
    this.config.cleanButton.action();
  }
  @HostListener('document:keydown.tab', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('document:keyup.tab', ['$event'])
  onKeyupHandler(event: KeyboardEvent) {
    event.preventDefault();
  }
}
