import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PlxIconModule } from '@pluxee-design-system/angular';
import { UserIdleModule } from 'angular-user-idle';
import { RealPipe } from 'ng2-brpipes';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { CallbackConnectComponent } from './callback-connect/callback-connect.component';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { GlobalErrorHandler } from './global-error-handler';
import { ServerErrorInterceptor } from './server-error.interceptor';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, CallbackConnectComponent],
  imports: [
    BrowserModule,
    RouterModule.forChild([]),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    CoreModule,
    FeaturesModule,
    AppRoutingModule,
    UserIdleModule.forRoot({ idle: 1800, timeout: 1 }),
    SharedModule,
    AuthConfigModule,
    PlxIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RealPipe,
  ],
})
export class AppModule {}
