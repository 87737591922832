<div class="table-filter__container" [ngClass]="{ 'table-rounded': true }">
  <ng-container *ngIf="!options">
    <plx-input
      [attr.name]="name"
      [id]="id"
      [placeholder]="placeholder"
      [mask]="maskFormat"
      (valueChange)="maskInputModelChangedHandler($event)"
      [unmask]="true"
      ngDefaultControl
    ></plx-input>

    <div [ngClass]="tableFilterClassName">
      <ng-content></ng-content>
    </div>
  </ng-container>

  <div *ngIf="options" class="grid grid-nogutter justify-content-center align-items-center">
    <div class="col-12">
      <plx-select
      [name]="name"
      [options]="options"
      (ngModelChange)="dropdownChange($event)"
      ></plx-select>
    </div>
  </div>
</div>
