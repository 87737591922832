export abstract class Mapper<M, D> {
  abstract convertToDto(model: M, id?: number): D;
  abstract convertToModel(dto: D): M;

  convertToDtos(models: Array<M>): Array<D> {
    return models.map((model: M) => this.convertToDto(model));
  }

  convertToModels(dtos: Array<D>): Array<M> {
    return dtos.map((dto: D) => this.convertToModel(dto));
  }
}
