import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DeliveryPlaceResponseDto } from 'src/app/shared/dtos/delivery-place.dto';
import { HttpService } from './http.service';
import { DeliveryPlaceOutputDto } from 'src/app/shared/dtos/delivery-place-output.dto';
import { FederationUnit } from 'src/app/shared/enums/federation-unit.enum';
import { FederalUnit } from 'src/app/shared/models/federal-unit.model';
import { BeneficiaryDeliveryPlaceDto } from 'src/app/shared/dtos/beneficiary-delivery-place.dto';

@Injectable({
  providedIn: 'root',
})
export class DeliveryPlaceService {
  constructor(private httpService: HttpService<any>) {}

  private readonly deliveryPlacesPath4c: string = '/suite/beneficiaries/deliveryplace';

  getCompanyDeliveryPlacesByClientId(clientId: string): Observable<DeliveryPlaceResponseDto> {
    const completePath = `${this.deliveryPlacesPath4c}/company/${clientId}`;
    return this.httpService.get(completePath);
  }

  addBeneficiaryDeliveryPlace(
    deliveryPlace: BeneficiaryDeliveryPlaceDto,
    clientId: string
  ): Observable<DeliveryPlaceOutputDto> {
    const completePath = `${this.deliveryPlacesPath4c}/company/${clientId}`;
    return this.httpService.post(completePath, deliveryPlace);
  }

  getStates(): Array<FederalUnit> {
    return [
      {
        label: 'Acre',
        value: FederationUnit.AC,
      },
      {
        label: 'Alagoas',
        value: FederationUnit.AL,
      },
      {
        label: 'Amapá',
        value: FederationUnit.AP,
      },
      {
        label: 'Amazonas',
        value: FederationUnit.AM,
      },
      {
        label: 'Bahia',
        value: FederationUnit.BA,
      },
      {
        label: 'Ceará',
        value: FederationUnit.CE,
      },
      {
        label: 'Distrito Federal',
        value: FederationUnit.DF,
      },
      {
        label: 'Espírito Santo',
        value: FederationUnit.ES,
      },
      {
        label: 'Goiás',
        value: FederationUnit.GO,
      },
      {
        label: 'Maranhão',
        value: FederationUnit.MA,
      },
      {
        label: 'Mato Grosso',
        value: FederationUnit.MT,
      },
      {
        label: 'Mato Grosso do Sul',
        value: FederationUnit.MS,
      },
      {
        label: 'Minas Gerais',
        value: FederationUnit.MG,
      },
      {
        label: 'Pará',
        value: FederationUnit.PA,
      },
      {
        label: 'Paraíba',
        value: FederationUnit.PB,
      },
      {
        label: 'Paraná',
        value: FederationUnit.PR,
      },
      {
        label: 'Pernambuco',
        value: FederationUnit.PE,
      },
      {
        label: 'Piauí',
        value: FederationUnit.PI,
      },
      {
        label: 'Rio de Janeiro',
        value: FederationUnit.RJ,
      },
      {
        label: 'Rio Grande do Norte',
        value: FederationUnit.RN,
      },
      {
        label: 'Rio Grande do Sul',
        value: FederationUnit.RS,
      },
      {
        label: 'Rondônia',
        value: FederationUnit.RO,
      },
      {
        label: 'Roraima',
        value: FederationUnit.RR,
      },
      {
        label: 'Santa Catarina',
        value: FederationUnit.SC,
      },
      {
        label: 'São Paulo',
        value: FederationUnit.SP,
      },
      {
        label: 'Sergipe',
        value: FederationUnit.SE,
      },
      {
        label: 'Tocantins',
        value: FederationUnit.TO,
      },
    ];
  }

  getStateByFederationUnit(federationUnit: string) {
    return this.getStates().find((state) => {
      return state.value.toString() === federationUnit;
    });
  }
}
