import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskTicket'
})
export class MaskTicketPipe implements PipeTransform {
  transform(value?: any, args?: any): any {
    return value
      ? `${value.slice(0, 5)}.${value.slice(5, 10)} ${value.slice(10, 15)}.${value.slice(15, 21)} ${value.slice(
          21,
          26
        )}.${value.slice(26, 32)}  ${value.slice(32, 33)} ${value.slice(33)} `
      : value;
  }
}
