import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule as FormModulaAngular, ReactiveFormsModule } from '@angular/forms';
import { PlxInputModule, PlxInputsValueAcessor, PlxSelectModule } from '@pluxee-design-system/angular';
import { CalendarModule as PrimeNgCalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { BeneficiaryService } from 'src/app/core/services/beneficiary.service';
import { DeliveryPlaceService } from 'src/app/core/services/delivery-place.service';
import { MessageToastService } from 'src/app/core/services/message.toast.service';
import { AddressFormComponent } from './address-form/address-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    PrimeNgCalendarModule,
    FileUploadModule,
    FormModulaAngular,
    PlxInputModule,
    PlxSelectModule,
    PlxInputsValueAcessor,
  ],
  declarations: [AddressFormComponent],
  exports: [AddressFormComponent],
  providers: [DeliveryPlaceService, BeneficiaryService, MessageToastService, FormGroupDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class FormsModule {}
