import { Component, OnInit, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from './core/services/auth.service';
import { first, filter, map, mergeMap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sdx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  idleSubscription: Subscription = new Subscription;
  routerSubscription: Subscription = new Subscription;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private userIdle: UserIdleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.startIdleWatch();
    this.setBackgroundColorDynamic();
  }

  ngOnDestroy() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private startIdleWatch() {
    this.userIdle.startWatching();

    this.idleSubscription = this.userIdle
      .onTimeout()
      .pipe(first())
      .subscribe(() => this.authService.logout());
  }

  private setBackgroundColorDynamic() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => this.updateBackgroundColor(event['background']));
  }

  private updateBackgroundColor(styleClass: string) {
    this.renderer.setAttribute(this.document.body, 'class', '');
    if (styleClass) {
      this.renderer.addClass(this.document.body, styleClass);
    }
  }
}
